import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

export const HighlightSearchText = ({text, searchTerm = ''}) => {
  text = text ?? '';
  const matches = match(text, searchTerm);
  const parts = parse(text, matches);
  return (
    parts.map((part, index) =>
      part.highlight ?
        <span key={String(index)} style={{fontWeight: 500}}>
          {part.text}
        </span>
        : <strong key={String(index)} style={{fontWeight: 300}}>
          {part.text}
        </strong>
    ));
};
