import React, {useMemo} from 'react';
import {get} from 'lodash';
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridFilterInputMultipleSingleSelect,
  GridFilterInputSingleSelect,
} from '@mui/x-data-grid-pro';
import {useGetDecorationCategories, useListUsers, useListVendors} from '../../hooks/api';
import {initials} from '../../utils';
import {CounterCell, CustomerCell, DateCell, GridViewColumnHeader, LinkCell, StatusCell, TextCell, UserAvatarCell} from '../../componentsLib/PowerGrid';

const staticColumnInfo = {
  '_id': {hide: true, filterable: false},
  'actions': {
    minWidth: 60,
    width: 130,
    disableReorder: true,
    align: 'center',
    filterable: false,
    hideable: false,
  },
  'createdAt': {editable: false,},
  'createdById': {editable: false,},
  'customer.name': {
    filters: ['contains'],
    editable: false,
    renderCell: CustomerCell,
  },
  'deadline': {purpose: 'dueDate'},
  'number': {
    width: 133,
    filters: ['equals'],
    sort: -1,
    hideable: false,
    align: 'center',
    editable: false
  },
  'stripeInvoiceId': {
    editable: false,
    renderCell: ({value}) => value && <LinkCell value={'View Invoice'} href={`https://dashboard.stripe.com/invoices/${value}`}/>,
    valueGetter: ({row}) => row.stripeInvoiceId ?? row.digest?.quote?.stripeInvoiceId ?? null,
    filters: ['isEmpty', 'isNotEmpty'],
    align: 'center',
  },
  'viewed': {
    renderCell: CounterCell,
    zeroText: 'Not Viewed',
    text: 'Viewed',
    filters: ['is viewed', 'is not viewed', '=', '>=', '>', '<=', '<'],
    type: 'number',
  },
  'BOOLEAN': {},
  'BUILTIN': {},
  'DATE': {
    align: 'center',
    editable: true,
    filters: ['is', 'onOrAfter', 'after', 'before', 'onOrBefore'],
    renderCell: (props) => <DateCell {...props}/>,
    sort: -1,
    type: 'date',
  },
  'DECORATION': {
    filters: ['isAnyOf'],
    type: 'singleSelect',
    width: 162,
  },
  'NUMBER': {},
  'STATUS': {
    editable: true,
    filters: ['isAnyOf', 'isNoneOf'],
    type: 'singleSelect',
    width: 200,
    valueOptions: (field) => {
      const options = field.options?.map(({statusLabel}) => ({value: statusLabel, label: statusLabel})) ?? [];
      return [{value: null, label: '[NO STATUS]'}, ...options];
    },
    renderCell: (props) => <StatusCell {...props}/>,
  },
  'TEXT': {
    editable: true,
    filters: ['contains', 'startsWith', 'endsWith'],
    renderCell: (props) => <TextCell {...props}/>,
    sort: 1,
  },
  'USER': {
    align: 'center',
    editable: true,
    filters: ['isAnyOf'],
    type: 'singleSelect',
    renderCell: (props) => <UserAvatarCell {...props} />,
  },
  'VENDOR': {
    filters: ['isAnyOf'],
    type: 'singleSelect',
    width: 250,
    // TODO: VENDOR CELL
    // renderCell: Cells.VendorsCell,
    valueGetter({row, colDef: {valueOptions}}) {
      return row.digest?.vendorIds?.map((id) => valueOptions.find((option) => option.value === id)?.label).join(', ');
    }
  },
};

export function useViewColumns(viewState, {extraColumnInfo, updateField, refetch}) {
  const {data: {users}} = useListUsers();
  const {data: {vendors}} = useListVendors();
  const {data: {decorationCategories}} = useGetDecorationCategories();

  const dynamicColumnInfo = useMemo(() => ({
    'DECORATION': {
      valueOptions: decorationCategories?.map(({_id}) => ({value: _id, label: _id})) ?? [],
      // TODO: DECORATION CELL
      // renderCell: (params) => {
      //   const getLabel = (id) => {
      //     const row = (decorationCategories || []).find(({decorationIds}) => decorationIds.includes(id));
      //     return row?._id;
      //   };
      //   const decorationIds = params.row?.digest?.decorationIds;
      //   return decorationIds?.length > 0 && <Cells.ChipCell getLabel={getLabel} dataArray={decorationIds} />;
      // },
      // valueGetter({row}) {
      //   return row.digest?.decorationIds?.map((id) => (decorationCategories || []).find(({decorationIds}) => decorationIds.includes(id))?._id).join(', ');
      // },
    },
    'USER': {
      valueOptions: users?.filter(({status}) => status === 'ACTIVE').map(({fullName, _id}) => ({
        value: _id,
        label: fullName,
        fullName,
        initials: initials(fullName)
      })),
    },
    'VENDOR': {valueOptions: vendors?.map(({_id, name}) => ({value: _id, label: name})) ?? []},
  }), [decorationCategories, users, vendors]);

  return useMemo(() => {
    const context = {
      columns: [],
      allowAddColumns: viewState.allowAddFields,
      pinnedColumns: viewState.currentView.pinned,
      hiddenColumns: viewState.currentView.hidden,
      setColumnOrder: viewState.setFieldOrder,
      setPinnedColumns: viewState.setPinnedFields,
      setHiddenColumns: viewState.setHiddenFields,
      updateField,
      refetch,
    };
    if (viewState.fields?.length > 0) {
      let fieldCount = 0;
      context.columns = viewState.fields
        .filter((field) => field.builtin || ++fieldCount <= viewState.fieldLimit)
        .map((field) => {
          const {filters, sort, type, valueOptions, width, ...props} = {
            ...(staticColumnInfo[field.type] ?? {}),
            ...(dynamicColumnInfo[field.type] ?? {}),
            ...(extraColumnInfo[field.type] ?? {}),
            ...(staticColumnInfo[field.path] ?? {}),
            ...(dynamicColumnInfo[field.path] ?? {}),
            ...(extraColumnInfo[field.path] ?? {}),
          };

          if ((field.hidden || props.hide) && !context.hiddenColumns.includes(field.path)) {
            context.hiddenColumns = [...context.hiddenColumns, field.path];
          }

          return {
            minWidth: 120,
            headerName: field.title,
            headerAlign: 'center',
            deletable: !field.builtin,
            field: field.path,
            viewField: field,
            renderHeader: (params) => (<GridViewColumnHeader colDef={params.colDef}/>),
            valueGetter: ({row, field: fieldPath}) => get(row, fieldPath),
            valueSetter: ({row, value}) => updateField({id: row._id, field: field.path, value}),
            width: viewState.currentView.widths[field.path] ?? width ?? 140,
            ...(filters ? {filterOperators: getFilteredGridOperators(filters, type)} : {}),
            ...(sort == null ? {sortable: false} : {sortingOrder: sort > 0 ? ['asc', 'desc'] : ['desc', 'asc']}),
            type,
            valueOptions: typeof valueOptions === 'function' ? valueOptions(field) : valueOptions,
            ...props,
            cellClassName: `power-grid-cell cell-data-type-${field.type.toLowerCase()} ${props.editable ?? !field.builtin ? 'editable' : 'readonly'} ${props.cellClassName ?? ''}`.trim(),
            headerClassName: `column-header ${props.headerClassName ?? ''}`.trim(),
            addColumn: async (fieldType, columnName, after) => await viewState.addField(fieldType, columnName, field.path, after),
            deleteColumn: async () => await viewState.deleteField(field.path),
            renameColumn: async (newName) => await viewState.renameField(field.path, newName),
            setWidth: async (newWidth) => await viewState.setFieldWidth(field.path, newWidth),
            ...(field.type !== 'STATUS' ? {} : {
              setOptions: async (newOptions) => {
                const res = await viewState.setFieldOptions(field.path, newOptions);
                await refetch?.();
                return res;
              }
            }),
            context,
          };
        });
    }
    return context;
  }, [viewState, updateField, refetch, dynamicColumnInfo, extraColumnInfo]);
}

function getFilteredGridOperators(filters, fieldType) {
  let ops;
  switch (fieldType) {
    case 'User':
    case 'singleSelect':
      ops = [
        ...getGridNumericOperators().filter(({value}) => ['isEmpty', 'isNotEmpty'].includes(value)),
        {
          value: 'is',
          getApplyFilterFn: () => () => true,
          InputComponent: GridFilterInputSingleSelect,
        },
        {
          value: 'not',
          getApplyFilterFn: () => () => true,
          InputComponent: GridFilterInputSingleSelect,
        },
        {
          value: 'isAnyOf',
          getApplyFilterFn: () => () => true,
          InputComponent: GridFilterInputMultipleSingleSelect,
        },
        {
          value: 'isNoneOf',
          label: 'is none of',
          getApplyFilterFn: () => () => true,
          InputComponent: GridFilterInputMultipleSingleSelect,
        },
      ];
      break;

    case 'date':
      ops = getGridDateOperators(false);
      break;

    case 'number':
      ops = [
        {value: 'isNotZero', label: 'is not zero', getApplyFilterFn: () => () => true},
        {value: 'isZero', label: 'is zero', getApplyFilterFn: () => () => true},
        {value: 'isNotZero', label: 'is viewed', getApplyFilterFn: () => () => true},
        {value: 'isZero', label: 'is not viewed', getApplyFilterFn: () => () => true},
        ...getGridNumericOperators(),
      ];
      break;

    default:
      ops = getGridStringOperators();
      break;
  }
  return filters
    .map((filter) => ops.find(({value, label}) => label === filter || value === filter))
    .filter(Boolean);
}
