import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Column} from '../Layout';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';
import {Popper} from '@material-ui/core';
import Grow from '@material-ui/core/Grow';

registerGlobalStyle('.modeless-popover, .MuiPopperUnstyled-root', (theme) => ({
  backgroundColor: theme.colors.transparent,
  zIndex: theme.zIndex.modal,
  'ul': {minWidth: 'var(--ulMinWidth)'},
  // This was added, but it breaks the JobBoard/SalesBoard grid popups (filter etc.), and I'm not sure why it was added
  // '&>.popover-elevation, &>.MuiPaper-elevation': {overflow: 'hidden',},
  '&.modeless-dialog': {
    '&>.popover-elevation': {
      border: `1px solid ${theme.colors.border.main}`,
      padding: theme.spacing(2),
    },
    '.row:has(>button.nav-positive)': {
      paddingTop: theme.spacing(2),
      justifyContent: 'space-between',
    }
  }
}));

const ORIGINS = {
  'bottom-end': 'top right',
  'bottom-start': 'top left',
  'bottom': 'top center',

  'left-end': 'bottom right',
  'left-start': 'top right',
  'left': 'center right',

  'right-end': 'bottom left',
  'right-start': 'top left',
  'right': 'center left',

  'top-end': 'bottom right',
  'top-start': 'bottom left',
  'top': 'bottom center',
};

export const Placement = {
  AutoEnd: 'auto-end',
  AutoStart: 'auto-start',
  Auto: 'auto',
  BottomEnd: 'bottom-end',
  BottomRight: 'bottom-end',
  BottomStart: 'bottom-start',
  Bottom: 'bottom',
  LeftEnd: 'left-end',
  LeftStart: 'left-start',
  Left: 'left',
  RightEnd: 'right-end',
  RightStart: 'right-start',
  Right: 'right',
  TopEnd: 'top-end',
  TopStart: 'top-start',
  Top: 'top',
};

export function ModelessPopover({className, anchorEl, closeOnClick, dialog, hidden, open, placement, onClose, onClickAway, onShownChange, children}) {
  const [shown, setShown] = useState(open);

  const handleShown = useCallback((isShown) => {
    onShownChange?.(isShown);
    setShown(isShown);
  }, [onShownChange]);

  return (!!(open || shown) && !!anchorEl &&
    <_ModelessPopover
      className={className}
      anchorEl={anchorEl}
      closeOnClick={closeOnClick}
      dialog={dialog}
      hidden={hidden}
      open={open}
      placement={placement}
      onClickAway={onClickAway}
      onClose={onClose}
      onShownChange={handleShown}
    >
      {children}
    </_ModelessPopover>
  );
}

ModelessPopover.propTypes = {
  className: HoopsPropTypes.className,
  anchorEl: PropTypes.object,
  closeOnClick: PropTypes.bool,
  dialog: PropTypes.bool,
  hidden: HoopsPropTypes.bool,
  placement: PropTypes.string,
  open: PropTypes.bool,
  onClickAway: HoopsPropTypes.func,
  onClose: PropTypes.func,
  onShownChange: PropTypes.func,
  children: HoopsPropTypes.children,
};

export function _ModelessPopover({className, anchorEl, closeOnClick, hidden, open, placement, onClickAway, onClose, onShownChange, dialog, children}) {
  const [_open, setOpen] = useState(open);
  const popoverRef = useRef();

  const handleAbsorbEvent = useCallback((e) => {
    if (popoverRef.current && popoverRef.current.contains(e.target)) {
      e.stopPropagation();
      if (e.target.tagName !== 'INPUT' && e.target.closest('a') == null) {
        e.preventDefault();
      }
    }
  }, []);

  const handleClick = useCallback((e) => {
    if (closeOnClick) {
      onClose(e);
    }
    handleAbsorbEvent(e);
  }, [closeOnClick, handleAbsorbEvent, onClose]);

  const handleKeyDown = useCallback((e) => {
    if (onClose && e.key === 'Escape') {
      onClose(e);
    }
  }, [onClose]);

  const handleEnter = useCallback(() => {
    onShownChange(true);
    setOpen(true);
  }, [onShownChange]);

  const handleExited = useCallback(() => {
    if (!hidden) {
      setOpen(false);
      onShownChange(false);
    }
  }, [hidden, onShownChange]);

  useEffect(() => {
    if (!open && hidden) {
      setOpen(false);
      onShownChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    function windowClick(e) {
      // First check if the event occurs outside the popover and outside the anchor element
      if ((popoverRef.current && !popoverRef.current.contains(e.target)) &&  (anchorEl && !anchorEl.contains(e.target))) {
        // Ignore clicks in other popovers, unless the popover contains our anchor - making it our parent
        const rootPopover = e.target.closest('.modeless-popover, .modal-dialog');
        if (!rootPopover || rootPopover.contains(anchorEl)) {
          onClickAway?.(e);
          onClose(e);
        }
      }
    }
    window.addEventListener('mousedown', windowClick, true);
    return () => window.removeEventListener('click', windowClick);
  }, [anchorEl, onClickAway, onClose]);

  const minWidth = useMemo(() => `${(anchorEl?.offsetWidth ?? 0) + 8}px`, [anchorEl]);

  if (!placement) {
    placement = 'bottom';
  }

  return (
    <Popper ref={popoverRef}
      className={classNames([className, 'modeless-popover transform-source', dialog && 'modeless-dialog'])}
      anchorEl={anchorEl}
      open={open || _open}
      placement={placement}
    >
      {({TransitionProps, placement: effectivePlacement}) => (
        <Grow {...TransitionProps} in={open && !hidden} appear={true} onEnter={handleEnter} onExited={handleExited} style={{transformOrigin: ORIGINS[effectivePlacement] ?? 'top left'}}>
          <Column paper className={'popover-elevation'} onClick={handleClick} onKeyDown={handleKeyDown} onMouseDown={handleAbsorbEvent}>
            <Column scroll style={{'--ulMinWidth': minWidth}}>
              {children}
            </Column>
          </Column>
        </Grow>
      )}
    </Popper>
  );
}

_ModelessPopover.propTypes = {
  className: HoopsPropTypes.className,
  anchorEl: HoopsPropTypes.object,
  dialog: HoopsPropTypes.bool,
  hidden: HoopsPropTypes.bool,
  placement: HoopsPropTypes.string,
  onClickAway: HoopsPropTypes.func,
  onClose: HoopsPropTypes.func,
  open: HoopsPropTypes.bool,
  onShownChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

export function useModelessPopoverState() {
  const [open, setOpen] = useState(false);
  const anchorElRef = useRef();

  const openPopover = useCallback(() => {
    setOpen(true);
  }, []);

  const closePopover = useCallback(() => {
    setOpen(false);
  }, []);

  const togglePopover = useCallback(() => {
    if (open) {
      closePopover();
    } else {
      openPopover();
    }
  }, [closePopover, open, openPopover]);

  return useMemo(() => ({
    anchorEl: anchorElRef.current,
    anchorElRef,
    closePopover,
    open: !!(open && anchorElRef.current),
    openPopover,
    togglePopover,
  }), [closePopover, open, openPopover, togglePopover]);
}
