import {initTemplateSubstitutions} from './templateSubstitutions';

export const decorationSubstitutions = initTemplateSubstitutions({
  name: {},
  vendor: {field: 'vendorName'},
  category: {},
  position: {},
  color: {},
  size: {},
});

export const oneOffDecorationSubstitutions = initTemplateSubstitutions({
  name: {},
  vendor: {field: 'vendorName'},
  position: {},
  color: {},
  size: {},
});
