import React, {useCallback, useState} from 'react';
import {get} from 'lodash';
import {useWysiwygContext, WysiwygEditor} from './WysiwygEditor';
import {BoxGrid} from '../Layout';
import {Button} from './Button';
import {ReadMore as InsertTextIcon} from '@mui/icons-material';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';
import {BodyText} from '../Text';

registerGlobalStyle('.wysiwyg-substitution', (theme) => ({'.substitution-insert-grid': {padding: theme.spacing(1, 0),}}));

export function WysiwygSubstitution({className, columns, fields, insertMissingFieldKeys, substitutions, textAlignment, value, onChange, children}) {
  const {insertText} = useWysiwygContext();
  const [positions] = useState(columns && positionSubstitutions(columns, substitutions));

  const insertSubstitution = useCallback((subs) => {
    insertText({text: fields ? get(fields, subs.field) || `{{${subs.key}}}` : `{{${subs.key}}}`, addToNewLine: true});
  }, [fields, insertText]);

  return (
    <div className={classNames([className, 'wysiwyg-substitution'])}>
      {children}
      <BoxGrid className={'substitution-insert-grid'} gridTemplateColumns={'auto auto auto auto'}>
        {columns && Object.keys(columns).map((column) => (
          <BodyText key={column}>{column}</BodyText>
        ))}
        {Object.values(substitutions).map((subs) => (
          <Button
            key={subs.key}
            actionPrimary
            icon
            prefix={InsertTextIcon}
            tabIndex={-1}
            text={subs.name}
            disabled={!insertMissingFieldKeys && fields && get(fields, subs.field) === undefined}
            onClick={() => insertSubstitution(subs)}
            {...(columns ? {style: {gridColumn: positions[subs.key].column, gridRow: positions[subs.key].row}} : {})}
          />
        ))}
      </BoxGrid>
      {onChange &&
        <WysiwygEditor value={value} onChange={onChange} textAlignment={textAlignment}/>
      }
    </div>
  );
}

WysiwygSubstitution.propTypes = {
  className: HoopsPropTypes.className,
  columns: HoopsPropTypes.object,
  fields: HoopsPropTypes.object,
  insertMissingFieldKeys: HoopsPropTypes.bool,
  substitutions: HoopsPropTypes.object,
  textAlignment: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

function positionSubstitutions(columns, substitutions) {
  const rows = [2, ...Object.keys(columns).map(() => 2)];

  return Object.values(substitutions).reduce((acc, subs) => {
    const column = (Object.values(columns).findIndex((groups) => groups.includes(subs.group)) + 1) || 1;
    acc[subs.key] = {column, row: rows[column]++};
    return acc;
  }, {});
}
