import React, {useCallback, useEffect, useState} from 'react';
import {CellContainer} from './CellContainer';
import {registerGlobalStyle} from '../../../theme';
import {TextInput} from '../../Basic';
import {BodyText} from '../../Text';

registerGlobalStyle('.power-grid .cell-data-type-text', (theme) => ({
  '.text-body': {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&.editable': {
    '.text-input': {
      border: 'none',
      padding: 0,
      backgroundColor: 'transparent',
    },
    '&::after': {
      content: '""',
      pointerEvents: 'none',
      position: 'absolute',
      inset: '0 0 0 0',
      border: '1px solid transparent',
      transition: theme.transitions.out.all,
    },
    '&:has(input:focus)::after': {
      border: `1px solid ${theme.colors.border.highlight}`,
      transition: theme.transitions.in.all,
    },
    '.cell-container:has(.text-container>.text-body:empty)': {justifyContent: 'center'},
    '.text-container:has(.text-body:empty)': {
      width: '100%',
      height: '100%',
      maxWidth: '12em',
      padding: theme.spacing(.5, 0),
      '>.text-body': {
        width: '100%',
        height: '100%',
        position: 'relative',
        '&::before': {
          content: '"Click To Add Text"',
          pointerEvents: 'none',
          position: 'absolute',
          inset: '0 0 0 0',
          padding: theme.spacing(.625, 1.5),
          borderRadius: theme.shape.borderRadius,
          border: `1px dashed ${theme.colors.text.medium}`,
          color: theme.colors.text.medium,
          textOverflow: 'ellipsis',
          textAlign: 'center',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          wordWrap: 'break-word',
          opacity: 0,
          transition: theme.transitions.out.all,
        },
      }
    },
    '&:hover .text-container > .text-body:empty::before': {
      opacity: 1,
      transition: theme.transitions.in.all,
    },
    '.cell-container > *::before': {
      bottom: theme.spacing(-1.125),
      right: theme.spacing(-1.625),
    },
    '.cell-container > *:has(>*:empty)::before': {
      bottom: theme.spacing(0.125),
      right: theme.spacing(-1.625),
    },
  },
}));

export function TextCell({value: _value, colDef, row}) {
  const [value, setValue] = useState(_value);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const handleClick = useCallback(() => {
    setEditing(true);
  }, []);

  const saveValue = useCallback((newValue) => {
    setEditing(false);
    if (newValue !== _value) {
      colDef.valueSetter({row, value: newValue});
    }
  }, [_value, colDef, row]);

  const handleBlur = useCallback((e) => {
    saveValue(e.currentTarget.value);
  }, [saveValue]);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setEditing(false);
      setValue(_value);
    } else if (e.key === 'Enter') {
      saveValue(e.target.value);
    }
  }, [_value, saveValue]);

  return (
    <CellContainer onClick={handleClick}>
      {!editing &&
        <div className={'text-container'}>
          <BodyText className={colDef.innerClassName}>{value}</BodyText>
        </div>
      }
      {editing &&
        <TextInput autoFocus selectOnFocus value={value} onBlur={handleBlur} onKeyDown={handleKeyDown} onChange={handleChange}/>
      }
    </CellContainer>
  );
}

