import React, {useCallback, useEffect} from 'react';
import {KeyboardBackspace as BackIcon, Settings as SettingsIcon} from '@mui/icons-material';
import {ToolboxDrawer, ToolboxSection} from '../../../componentsLib/Popovers';
import {Column, Row, usePageContext} from '../../../componentsLib/Layout';
import {Button, WysiwygProvider} from '../../../componentsLib/Basic';
import {HeadingText} from '../../../componentsLib/Text';
import {registerGlobalStyle} from '../../../theme';
import {SalesDoc, SalesDocItem} from '../Models/SalesDoc';
import {SalesDocSections, SalesDocSubSections} from '../Components/SalesDocSection';
import {
  AdditionalCostToolbox,
  CustomerActionsToolbox,
  CustomerEditToolbox,
  CustomerToolbox,
  DecorationToolbox,
  DocumentFooterToolbox,
  DocumentHeaderToolbox,
  GroupToolbox,
  ImageToolbox,
  ProductToolbox,
  ThingsToolbox
} from './SalesDocToolboxes';
import {ChooseTemplateToolbox, SettingsToolbox} from './SettingsToolboxes';
import {AddVendorToolbox} from './AddVendorToolbox';
import {PresentationHeaderToolbox} from './PresentationsToolboxes';
import {useMountEffect} from '../../../hooks';

registerGlobalStyle('.salesdoc-toolbox-drawer', (theme) => ({
  '.toolbox-drawer': {paddingTop: '73px',},
  '.toolbox-drawer-content': {padding: theme.spacing(1, 0, 2),},
  '.toolbox-heading': {
    '.settings-button': {
      position: 'relative',
      top: -3,
      marginBottom: -5,
      borderColor: 'transparent',
      boxShadow: 'none',
      color: theme.colors.text.main,
    },
    '&:hover .settings-button': {
      borderColor: theme.colors.palette.blue,
      color: theme.colors.text.highlight,
      '.button-slider': {width: 'var(--slideWidth)',}
    },
  },
  '.images-section': {
    '.view-all-button': {
      width: 100,
      height: 100,
    },
  },
}));

export function SalesDocToolboxDrawer({toolboxWidth}) {
  const {loading, salesDoc, salesDocChanged, selection, setSelection, toolboxOpen, setToolboxOpen, onToolboxVisibleChange} = usePageContext();

  const selectedGroup = salesDoc?.getFirstItemInGroup(selection.groupId);
  const selectedVariant = salesDoc?.getFirstVariantItem(selection.variantId);
  const selectedItem = salesDoc?.getItem(selection.itemId) ?? selectedVariant;
  const isPresentation = salesDoc?.documentType === SalesDoc.Type.PRESENTATION || salesDoc?.documentType === SalesDoc.Type.PRESENTATION_TEMPLATE;

  let toolbox;
  if (salesDoc) {
    if (salesDoc.docTemplateName == null && !salesDocChanged) {
      toolbox = ChooseTemplateToolbox;
    } else if (selection.showSettings || salesDoc.docTemplateId == null) {
      toolbox = SettingsToolbox;
    } else if (selection.subSection === SalesDocSubSections.addVendor) {
      toolbox = AddVendorToolbox;
    } else if (selection.section === SalesDocSections.customerActionButtons) {
      toolbox = CustomerActionsToolbox;
    } else if (selection.subSection === SalesDocSubSections.addCustomer || selection.subSection === SalesDocSubSections.editCustomer) {
      toolbox = CustomerEditToolbox;
    } else if (selection.section === SalesDocSections.customerSection) {
      toolbox = CustomerToolbox;
    } else if (selection.section === SalesDocSections.documentHeader && salesDoc.isPresentation()) {
      toolbox = PresentationHeaderToolbox;
    } else if (selection.section === SalesDocSections.documentHeader) {
      toolbox = DocumentHeaderToolbox;
    } else if (selection.section === SalesDocSections.documentTerms) {
      toolbox = DocumentFooterToolbox;
    } else if (selection.section === SalesDocSections.documentTotals) {
      toolbox = DocumentFooterToolbox;
    } else if (selection.section === SalesDocSections.product && selectedItem) {
      toolbox = ProductToolbox;
    } else if (selection.section === SalesDocSections.productImage && selectedItem) {
      toolbox = ImageToolbox;
    } else if (selection.section === SalesDocSections.decoration && selectedItem && selectedItem.isDecoration()) {
      toolbox = DecorationToolbox;
    } else if (selection.section === SalesDocSections.additionalCost && selectedItem) {
      toolbox = AdditionalCostToolbox;
    } else if (selection.section === SalesDocSections.groupHeaderSection) {
      toolbox = GroupToolbox;
    } else if (selectedGroup) {
      toolbox = ThingsToolbox;
    } else if (selection.section === SalesDocSections.imageGallery) {
      toolbox = ThingsToolbox;
    }
  }

  useEffect(() => {
    setToolboxOpen((prevOpen) => prevOpen || selection.section != null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  useEffect(() => {
    // In some cases, with grid pricing the selection can get out of sync with the columns
    // in the grid, so update the selection here.
    if (selectedItem && selection.itemId != null && selectedItem.itemId !== selection.itemId) {
      setSelection((prevSelection) => ({...prevSelection, itemId: selectedItem.itemId}));
    }
  }, [selectedItem, selectedItem?.itemId, selection?.itemId, setSelection]);

  // If an item was selected, and it has been deleted, try to select another item in the group
  useEffect(() => {
    // If no toolbox can be found for the current selection, use the group toolbox for the last group, this
    // might be refined if the selection has been deleted
    if (salesDoc && !toolbox) {
      const groupIds = salesDoc.getGroupIds();
      setSelection({section: SalesDocSections.groupSection, groupId: groupIds[groupIds.length - 1]});
    }

    if ((selection.groupId && !selectedGroup) || (selection.itemId && !selectedItem) || (selection.variantId && !selectedVariant)) {
      if (salesDoc?.deletedItemIndex >= 0) {
        const selItem = salesDoc.items[salesDoc.deletedItemIndex] ?? salesDoc.items[salesDoc.deletedItemIndex - 1];
        if (selItem) {
          if (selection.section === SalesDocSections.groupSection || selection.section === SalesDocSections.groupHeaderSection) {
            setSelection({section: selection.section, groupId: selItem.groupId});
          } else {
            switch (selItem.type) {
              case SalesDocItem.Type.VARIANT:
              case SalesDocItem.Type.PRODUCT_PLACEHOLDER:
                setSelection({section: SalesDocSections.product, groupId: selItem.groupId, itemId: selItem.itemId, variantId: selItem.variantId});
                break;

              case SalesDocItem.Type.DECORATION:
              case SalesDocItem.Type.DECORATION_PLACEHOLDER:
                setSelection({section: SalesDocSections.decoration, groupId: selItem.groupId, itemId: selItem.itemId});
                break;

              case SalesDocItem.Type.ADDITIONAL_COST:
                setSelection({section: SalesDocSections.additionalCost, groupId: selItem.groupId, itemId: selItem.itemId});
                break;

              case SalesDocItem.Type.GROUP_PLACEHOLDER:
                setSelection({section: SalesDocSections.groupSection, groupId: selItem.groupId});
                break;
            }
          }
        }
      }
    }
  });

  return (
    <ToolboxDrawer
      className={['salesdoc-toolbox-drawer', isPresentation && 'presentation', salesDoc?.getRounding() > 4 && 'toolbox-tight']}
      open={toolboxOpen}
      onOpenChange={setToolboxOpen}
      onVisibleChange={onToolboxVisibleChange}
      width={toolboxWidth}
    >
      {toolbox && !loading &&
        <WysiwygProvider>
          {React.createElement(toolbox)}
        </WysiwygProvider>
      }
    </ToolboxDrawer>
  );
}

export function SalesDocToolbox({className, heading, onBack, children, noSettings, noBack}) {
  const {salesDoc, selection, setSelection} = usePageContext();

  useMountEffect(() => {
    const elem = document.querySelector('.toolbox-drawer-content');
    if (elem) {
      elem.scrollTo(0, 0);
    }
  });

  const handleBack = useCallback(() => {
    if (onBack) {
      onBack();
    } else if (selection.showSettings) {
      setSelection((prev) => ({...prev, showSettings: false}));
    } else {
      setSelection({});
    }
  }, [onBack, selection, setSelection]);

  const handleSettings = useCallback(() => {
    setSelection((prev) => ({...prev, showSettings: true}));
  }, [setSelection]);

  return (
    <Column className={['salesdoc-toolbox', salesDoc.isPresentation() && 'presentation-toolbox', className]}>
      {heading &&
        <ToolboxSection className={'toolbox-heading'} noSep>
          <Row className='toolbox-heading' alignBaseline gap>
            {!noBack &&
              <Button prefix={BackIcon} onClick={handleBack} />
            }
            <HeadingText x24 dark>
              {heading}
            </HeadingText>
            {!noSettings &&
              <Button className={'settings-button'} prefix={SettingsIcon} text={'View Template Settings'} navStandard noWrap slideText onClick={handleSettings}/>
            }
          </Row>
        </ToolboxSection>
      }
      {children}
    </Column>
  );
}
