import {useCallback, useEffect, useRef} from 'react';

export function GlobalDragDropProvider() {
  const inWindowRef = useRef(false);

  const handleDragOver = useCallback((e) => {
    if (inWindowRef.current) {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'none';
    }
  }, []);

  const handleDragEnter = useCallback((e) => {
    if (!inWindowRef.current) {
      let numFiles = 0, numImages = 0, numPDFs = 0;
      for (let item of e.dataTransfer.items) {
        if (item.kind === 'file') {
          numFiles += 1;
          if (item.type.startsWith('image/')) {
            numImages += 1;
          }
          if (item.type.includes('pdf')) {
            numPDFs += 1;
          }
        }
      }
      if (numFiles > 0) {
        inWindowRef.current = true;
        e.dataTransfer.dropEffect = 'none';
        e.preventDefault();
        document.body.classList.add(numFiles === 1 ? 'dragging-single' : 'dragging-multi');
        document.body.classList.add('dragging-files');
        if (numImages > 0) {
          document.body.classList.add('dragging-images');
        }
        if (numPDFs > 0) {
          document.body.classList.add('dragging-pdfs');
        }
      }
    }
  }, []);

  const endDragDrop = useCallback(() => {
    if (inWindowRef.current) {
      inWindowRef.current = false;
      document.body.classList.remove(
        'dragging-single',
        'dragging-multi',
        'dragging-files',
        'dragging-images',
        'dragging-pdfs'
      );
    }
  }, []);

  const handleDragLeave = useCallback((e) => {
    if (inWindowRef.current) {
      if (e.clientX === 0 && e.clientY === 0) {
        endDragDrop();
      }
      e.preventDefault();
    }
  }, [endDragDrop]);

  const handleDrop = useCallback((e) => {
    if (inWindowRef.current) {
      endDragDrop();
      e.preventDefault();
    }
  }, [endDragDrop]);

  useEffect(() => {
    document.addEventListener('dragenter', handleDragEnter, true);
    document.addEventListener('dragleave', handleDragLeave, true);
    document.addEventListener('dragover', handleDragOver, true);
    document.addEventListener('drop', handleDrop, true);

    return () => {
      document.removeEventListener('dragenter', handleDragEnter, true);
      document.removeEventListener('dragleave', handleDragLeave, true);
      document.removeEventListener('dragover', handleDragOver, true);
      document.removeEventListener('drop', handleDrop, true);
    };
  }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop]);

  return null;
}
