import React, {useCallback} from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {useSnackbar} from '../../hooks';
import {HoopsPropTypes} from '../utils';
import {filterFiles, MaxFileSize} from '../../utils';

registerGlobalStyle('.drop-zone', (theme) => ({
  position: 'relative',
  '&>.drop-zone-indicator': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '3rem',
    color: theme.colors.transparent,
    pointerEvents: 'none',
    outline: '2px dashed transparent ',
    outlineOffset: 2,
    borderRadius: theme.shape.borderRadius,
    transition: `${theme.transitions.out.outline}, ${theme.transitions.out.all}`,
    ':is(body.dragging-files .drop-zone-indicator)': {transition: `${theme.transitions.in.outline}, ${theme.transitions.in.all}`}
  },
  '&:is(body.dragging-single .drop-single), &:is(body.dragging-single .drop-multi), &:is(body.dragging-multi .drop-multi)': {
    '&:is(body.dragging-files .drop-files), &:is(body.dragging-images .drop-images), &:is(body.dragging-pdfs .drop-pdfs)': {
      '& > .drop-zone-indicator': {
        backgroundColor: theme.colors.background.backdropWhite,
        outlineColor: theme.colors.border.main,
        color: theme.colors.text.medium,
      }
    }
  }
}));

export function DropZone({className, accept, multi, text, maxSize, onDrop, children}) {
  const snack = useSnackbar();

  const acceptImage = accept.includes('image/');
  const acceptPDF = accept.includes('application/pdf');

  let typeClasses = [];
  typeClasses.push(multi ? 'drop-multi' : 'drop-single');
  typeClasses.push(acceptImage ? 'drop-images' : 'drop-files');
  if (acceptPDF) {
    typeClasses.push('drop-pdfs');
  }

  const handleDragOver = useCallback((e) => {
    e.dataTransfer.dropEffect = 'copy';
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    if (multi || e.dataTransfer.files.length === 1) {
      onDrop?.(filterFiles([...e.dataTransfer.files], {maxSize, accept, multi}, snack));
    }
  }, [accept, maxSize, multi, onDrop, snack]);

  return (
    <div className={classNames([className, 'drop-zone', ...typeClasses])} onDragOver={handleDragOver} onDrop={handleDrop}>
      <div className={'drop-zone-indicator'}>
        {text}
      </div>
      {children}
    </div>
  );
}

DropZone.propTypes = {
  accept: HoopsPropTypes.string,
  className: HoopsPropTypes.className,
  maxSize: HoopsPropTypes.oneOf(Object.values(MaxFileSize)),
  multi: HoopsPropTypes.bool,
  onDrop: HoopsPropTypes.func,
  text: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
