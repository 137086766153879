
export function initials(string, limit = 2) {
  if (string) {
    string = string.trim();
    if (string) {
      return string.split(/\s+/, limit).reduce((i, w) => i + w[0].toUpperCase(), '');
    }
  }
  return '';
}

export const getInitials = initials;
