import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Column, Row, Table, TableCell, TableRow, usePageContext} from '../../../componentsLib/Layout';
import {
  KeyboardBackspace as BackIcon,
  DescriptionOutlined as PdfIcon,
  VisibilityOff as VisibilityOffIcon,
  AddCircle as AddIcon,
  FileCopy as CopyIcon,
  Delete as DeleteIcon,
  Info as InfoIcon,
  DragIndicator,
  Favorite as LikeIcon
} from '@mui/icons-material';
import {BodyText, HeadingText} from '../../../componentsLib/Text';
import {registerGlobalStyle} from '../../../theme';
import {asCurrencyRateString, asCurrencyString, sortSizes, valueFromEvent} from '../../../utils';
import {SalesDocSection, SalesDocSections} from '../Components/SalesDocSection';
import {Button, ButtonTheme, HoverTools, TextInput, ToolTip, WysiwygText} from '../../../componentsLib/Basic';
import {RectangularSkeleton, TextSkeleton} from '../../../componentsLib/Skeletons';
import {MultiRowDragDrop} from '../../../componentsLib/DragDrop';
import {ChipList} from '../../../componentsLib/Chips/ChipList';
import {SalesDocItem} from '../Models/SalesDoc';
import {isEmpty} from 'lodash';
import {Chip} from '../../../componentsLib/Chips';
import {stripHTML} from '../../../utils/stripHtml';
import classNames from 'classnames';
import {ImageGallery} from '../../../componentsLib/Basic/ImageGallery';
import {Divider} from '@mui/material';
import {ImagePreview} from '../../../componentsLib/Basic/ImagePreview';
import {featureFlags} from '../../../utils/featureFlag';
import {FeatureFlag} from '../../../componentsHoops';

registerGlobalStyle('.presentation-view', (theme) => ({
  flex: 1,
  '.presentation-header': {
    'span': {width: '100%'},
    'p': {textAlign: 'center'},
    'img': {
      maxWidth: 250,
      maxHeight: 166
    },
    padding: theme.spacing(6, 0, 3, 0),
  },
  '.category-menu': {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0, 0, 1),
    display: 'flex',
    justifyContent: 'center',
    '.selected-category': {textDecoration: 'underline'}
  },
  '.product-gallery': {
    display: 'flex',
    direction: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    '.product': {
      width: 346,
      borderRadius: theme.shape.borderRadius,
      '.product-tile-container': {
        position: 'relative',
        cursor: 'pointer',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
      },
      '.product-thumbnail': {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 340px',
        backgroundPosition: 'center',
        width: 330,
        height: 330,
        marginBottom: theme.spacing(2),
        border: `1px solid ${theme.colors.border.light}`,
        borderRadius: theme.shape.borderRadius,
        color: theme.colors.text.highlight,
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        'img, canvas': {
          height: '100%',
          objectFit: 'contain',
          transition: 'transform 2s'
        },
        '&:hover': {'img, canvas': {transform: 'scale(1.1)',}}
      },
      '.product-thumbnail.empty': {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {borderColor: theme.colors.border.highlight}
      },
      '.product-like-button': {
        position: 'absolute',
        justifyContent: 'end',
        right: theme.spacing(2),
        top: 280,
        cursor: 'pointer',
        height: 'fit-content',
        background: 'none',
        boxShadow: 'none',
        '.button': {padding: theme.spacing(1),}
      },
    },
  },
  '.product-view': {
    '.back-button': {margin: theme.spacing(2, 0, 0, 3)},
    '.add-item-button': {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      height: 'min-content',
      '.row': {boxShadow: 'none'}
    },
    '.add-to-cart-button': {width: '100%',},
    '.presentation-variant, .presentation-decorations, .presentation-additional-charges, .presentation-action-buttons': {
      padding: theme.spacing(1.5, 1),
      minWidth: 460,
      width: 'min-content',
      '.presentation-row': {width: '100%'},
      '.row': {
        '.tooltip-container': {
          '.button': {
            padding: theme.spacing(0.85, 1),
            boxShadow: '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
          }
        }
      },
    },
    '.presentation-image-column': {
      position: 'relative',
      '.image-gallery': {
        padding: theme.spacing(1),
        maxWidth: 560,
        '.image-container': {
          '.image-preview': {'.react-pdf__Document': {minWidth: 460}},
          '.pagination-controller': {boxShadow: theme.shadows.hoverElevation,}
        }
      }
    },
    '.presentation-data-column': {
      '.salesdoc-section': {width: '100%'},
      '.presentation-decorations.skeleton, .presentation-additional-charges.skeleton': {
        '.text-heading': {display: 'flex'},
        '.tooltip-container': {display: 'flex', alignItems: 'center'},
        'svg': {color: theme.colors.text.medium, marginLeft: theme.spacing(2)},
      },
      '.presentation-variant, .presentation-decorations, .presentation-additional-charges': {
        'text-heading': {display: 'flex', alignItems: 'center'},
        '.chip-pricing-table-section': {
          width: '100%',
          borderRadius: 12,
          '&.pricing-table-border': {borderRadius: '12px 0 0 0',},
          '.selection-action-buttons': {
            position: 'absolute',
            justifyContent: 'end',
            right: theme.spacing(1),
            bottom: theme.spacing(-1.5),
            cursor: 'pointer',
          }
        },
        '.chip-container': {
          columnGap: theme.spacing(1),
          rowGap: theme.spacing(1),
        },
        '.chip': {
          cursor: 'default',
          background: theme.colors.background.white,
          border: `1px solid ${theme.colors.border.dark}`,
          justifyContent: 'center',
          alignItems: 'center',
          width: 'min-content',
          '.text-body': {color: theme.colors.text.main, fontSize: theme.fontSize(14)},
        },
        '.tooltip-container': {
          display: 'flex',
          'svg': {
            color: theme.colors.text.medium,
            width: 16,
            height: 16,
            position: 'static',
          }
        },
        '.selectable-chip': {
          position: 'relative',
          overflow: 'visible',
          width: 'fit-content',
          '.text-body': {fontSize: 14},
          '.visibility-off-icon': {
            display: 'flex',
            padding: 0,
            opacity: 0,
            width: 0,
            transition: theme.transitions.out.all,
            'svg': {color: theme.colors.text.medium,}
          },
          '.selection-action-buttons': {
            position: 'absolute',
            justifyContent: 'end',
            left: 'calc(100% - .5rem)',
            bottom: theme.spacing(-.5),
            cursor: 'pointer',
            opacity: 0,
            zIndex: theme.zIndex.hovers,
            'svg': {
              color: 'unset',
              width: 24,
              height: 24,
              position: 'static',
            }
          },
          '&:hover': {
            border: `1px solid ${theme.colors.border.light}`,
            boxShadow: `inset 0 0 0 1px ${theme.colors.border.light}`,
            '.selection-action-buttons': {opacity: 1}
          },
          '&.item-hidden-from-customer': {
            borderColor: theme.colors.border.light,
            color: theme.colors.text.medium,
            '.visibility-off-icon': {
              opacity: 1,
              width: 20,
              transition: theme.transitions.in.all,
            },
          },
        },
        '.selectable-chip.selected': {
          background: theme.colors.background.white,
          border: `1px solid ${theme.colors.border.highlight}`,
          boxShadow: `inset 0 0 0 1px ${theme.colors.border.highlight}, 0 0 0 1px ${theme.colors.border.highlight}`,
          '.selection-action-buttons': {
            opacity: 1,
            boxShadow: theme.shadows.hoverElevation,
          },
        },

      },
      '.presentation-pricing-table': {
        width: '100%',
        borderRadius: 0,
        margin: theme.spacing(.5, 0, 0, 0),
        'td:first-of-type': {width: 80},
        'td': {
          padding: theme.spacing(.5),
          borderStyle: 'solid',
          borderColor: theme.colors.border.lightest,
        }
      }
    }
  },
  '.currency-prefix': {
    '&:before': {
      color: theme.colors.text.medium,
      paddingRight: theme.spacing(.5)
    }
  },
  '&.presentation-builder': {
    minWidth: 1100,
    '.category-menu': {
      paddingLeft: theme.spacing(2),
      '.all-products-button': {cursor: 'default'},
    },
    '.product-gallery': {
      '.product': {
        background: theme.colors.background.white,
        '.product-drag-anchor': {
          display: 'flex',
          position: 'absolute',
          justifyContent: 'start',
          cursor: 'grab',
          height: 'fit-content'
        },
        '.product-action-buttons': {
          position: 'absolute',
          justifyContent: 'end',
          right: theme.spacing(1),
          bottom: theme.spacing(1),
          cursor: 'pointer',
          height: 'fit-content'
        },
      },
      '.product.empty': {padding: theme.spacing(1),},
    },
  },
  '@media screen and (max-width:480px)': {
    '.product-view': {
      '.presentation-variant, .presentation-decorations, .presentation-additional-charges, .presentation-action-buttons': {
        minWidth: 'auto',
        width: 'auto',
      },
    },
  }
}));

export const PresentationView = () => {
  const {selection, isBuilder} = usePageContext();
  const [galleryScrollTop, setGalleryScrollTop] = useState(0);

  // If the user is returning to the gallery restotre the gallery scroll position for all other cases scroll to the top.
  useEffect(() => {
    const scrollContainer = document.querySelector('.page-scroll-container');
    if (!selection.groupId) {
      scrollContainer.scrollTo(0, galleryScrollTop);
    } else {
      scrollContainer.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection.groupId]);

  const handleSetGalleryScrollTop = useCallback((e) => {
    if (e.target.closest('.product')) {
      const scrollContainer = document.querySelector('.page-scroll-container');
      setGalleryScrollTop(scrollContainer.scrollTop);
    }
  }, []);

  return (
    <div className={classNames(['presentation-view', isBuilder && 'presentation-builder'])} onClick={handleSetGalleryScrollTop}>
      <PresentationHeader />
      <Divider flexItem className='salesdoc-divider-horizontal' />
      {!selection.groupId &&
        <ProductGallery />
      }
      {selection.groupId &&
        <ProductView />
      }
    </div>
  );
};

function PresentationHeader() {
  const {salesDoc, selection, isBuilder} = usePageContext();
  const headerDescription = salesDoc.getSubstitutedDescription();
  return (
    <>
      {isBuilder &&
        <SalesDocSection section={SalesDocSections.documentHeader} groupId={selection.groupId}>
          <Row>
            <Column justifyCenter alignCenter fillWidth className='presentation-header'>
              {salesDoc.template.headerImage &&
                <img alt='' src={salesDoc.template.headerImage} />
              }
              {headerDescription &&
                <WysiwygText text={headerDescription} />
              }
              {!headerDescription &&
                <>
                  <TextSkeleton animation={false} width={400} />
                  <TextSkeleton animation={false} width={300} />
                </>
              }
            </Column>
          </Row>
        </SalesDocSection>
      }
      {!isBuilder &&
        <Row>
          <Column justifyCenter alignCenter fillWidth className='presentation-header'>
            {salesDoc.template.headerImage &&
              <img alt='' src={salesDoc.template.headerImage} />
            }
            <WysiwygText text={headerDescription} />
          </Column>
        </Row>
      }
    </>
  );
}

function ProductGallery() {
  const {salesDoc, isBuilder, setSelection} = usePageContext();
  const [selectedCategory, setSelectedCategory] = useState(false);
  const categories = salesDoc.getAllCategories() ?? [];

  const handleSelectCategory = useCallback((_, category) => {
    setSelectedCategory(category);
  }, [setSelectedCategory]);

  const handleDragEnd = useCallback((draggedItem, array) => {
    const afterGroup = salesDoc.getGroupIds().filter((groupId) => groupId !== draggedItem.props.groupId)[array.indexOf(draggedItem) - 1];
    salesDoc.moveGroup({
      afterItem: salesDoc.items.findLast((item) => item.groupId === afterGroup),
      groupId: draggedItem.props.groupId,
    });
  }, [salesDoc]);

  const handleAddNewGroup = useCallback(() => {
    const doc = salesDoc.addGroup(salesDoc.items[salesDoc.items.length - 1]);
    setSelection({groupId: doc.newItem.groupId, section: SalesDocSections.product});
  }, [salesDoc, setSelection]);

  const groupsFilteredByCategory = useMemo(() => {
    if (!selectedCategory) {
      return salesDoc.getGroupIds();
    }
    return Object.keys(salesDoc.items.reduce((acc, item) => item.categories?.includes(selectedCategory) ? {...acc, [item.groupId]: true} : acc, {}));
  }, [salesDoc, selectedCategory]);
  const hasGroupPlaceholder = groupsFilteredByCategory.some((groupId) => salesDoc.getFirstItemInGroup(groupId).type === SalesDocItem.Type.GROUP_PLACEHOLDER);

  return (
    <>
      <Row className={'category-menu'} gap>
        <Button actionStandard text='All Products' onClick={(e) => handleSelectCategory(e, null)} className={[!selectedCategory && 'selected-category']} />
        {categories.map((category) =>
          <Button
            key={category}
            actionStandard
            text={category}
            onClick={(e) => handleSelectCategory(e, category)}
            className={[category === selectedCategory && 'selected-category']}
          />
        )}
      </Row>

      <div className={'product-gallery'}>
        <MultiRowDragDrop onDragEnd={handleDragEnd} draggable={isBuilder}>
          {groupsFilteredByCategory.map((groupId) => (
            <ProductGalleryTile key={groupId} groupId={groupId} />
          ))}
        </MultiRowDragDrop>
        {isBuilder && !hasGroupPlaceholder &&
          <div className={'product empty'}>
            <div className={'product-thumbnail empty'} onClick={handleAddNewGroup}><AddIcon /></div>
          </div>
        }
      </div>
    </>
  );
}

function ProductGalleryTile({groupId}) {
  const {salesDoc, company, isBuilder, setSelection, likeGroup} = usePageContext();

  const items = salesDoc.getItemsInGroup(groupId);
  const primaryItem = items.find((item) => item.type === 'variant') ?? items[0];
  const images = primaryItem?.images ?? [];

  const variants = salesDoc.getVariantItems(primaryItem?.variantId).sort((a, b) => (+a.unitPrice) - (+b.unitPrice));
  const firstUnitPrice = asCurrencyRateString(variants[0]?.unitPrice);
  const lastUnitPrice = asCurrencyRateString(variants[variants.length - 1]?.unitPrice);

  const isLikedGroup = items[0].liked;

  const handleCopyGroup = useCallback((e) => {
    e.stopPropagation();
    salesDoc.copyGroup(groupId);
  }, [groupId, salesDoc]);

  const handleDeleteGroup = useCallback((e) => {
    e.stopPropagation();
    salesDoc.deleteGroup(groupId);
  }, [groupId, salesDoc]);

  const handleLikeGroup = useCallback(async (e) => {
    e.stopPropagation();
    if (isLikedGroup) {
      likeGroup({groupId: groupId, like: false});
    } else {
      likeGroup({groupId: groupId, like: true});
    }
  }, [groupId, likeGroup, isLikedGroup]);

  const handleSelectGroup = useCallback(() => {
    // Setting selection opens the product view
    const variantItem = salesDoc.getFirstProductVariantItemInGroup(groupId);
    if (variantItem) {
      setSelection({groupId, section: SalesDocSections.product, itemId: variantItem.itemId, variantId: variantItem.variantId});
    } else {
      setSelection({groupId, section: SalesDocSections.product});
    }
  }, [salesDoc, setSelection, groupId]);

  let productPriceText;
  if (firstUnitPrice === lastUnitPrice) {
    productPriceText = `${company.currencySymbol}${lastUnitPrice}`;
  } else {
    productPriceText = `${company.currencySymbol}${firstUnitPrice} - ${company.currencySymbol}${lastUnitPrice}`;
  }

  return (
    <>
      {primaryItem.type === SalesDocItem.Type.GROUP_PLACEHOLDER &&
        <div className={'product empty'} onClick={handleSelectGroup}>
          <div className={'product-thumbnail empty'}><AddIcon /></div>
        </div>
      }
      {primaryItem.isProductVariant() &&
        <div className={'product'} onClick={handleSelectGroup}>
          {isBuilder &&
            <div className={'product-tile-container hover-tools-container'} >
              <HoverTools className={'product-drag-anchor'} >
                <DragIndicator />
              </HoverTools>
              <HoverTools className={'product-action-buttons'} >
                <CopyIcon onClick={handleCopyGroup} />
                <DeleteIcon onClick={handleDeleteGroup} />
              </HoverTools>
              <div className={'product-thumbnail'}>
                <ImagePreview
                  className={'product-thumbnail-img'}
                  imageUrl={images[0]}
                  setByContainerWidth
                />
              </div>
              <BodyText text={primaryItem.name} />
              {primaryItem.showPricing && <BodyText dark>{productPriceText}</BodyText>}
            </div>
          }
          {!isBuilder &&
            <div className={'product-tile-container hover-tools-container'}>
              <FeatureFlag allow={featureFlags.likeProduct}>
                {salesDoc.template.likeButtonEnabled &&
                  <HoverTools className={'product-like-button'}>
                    <ToolTip
                      top
                      tip={<span style={{whiteSpace: 'pre-line'}}>{isLikedGroup ? 'Remove Like' : 'We’ll be notified when \n you like products.'}</span>}
                    >
                      <ButtonTheme color={salesDoc.template.likeButtonColor}>
                        <Button navStandard={!isLikedGroup} navPrimary={isLikedGroup} prefix={LikeIcon} onClick={handleLikeGroup} />
                      </ButtonTheme>
                    </ToolTip>
                  </HoverTools>
                }
              </FeatureFlag>
              <div className={'product-thumbnail'}>
                <img alt='' src={images[0]} />
              </div>
              <BodyText text={primaryItem.name} />
              {primaryItem.showPricing && <BodyText dark>{productPriceText}</BodyText>}
            </div>
          }
        </div >
      }
      {
        primaryItem.type !== SalesDocItem.Type.GROUP_PLACEHOLDER && !primaryItem.isProductVariant() &&
        <div className={'product'} onClick={handleSelectGroup}>
          {isBuilder &&
            <div className={'product-tile-container hover-tools-container'} >
              <HoverTools className={'product-drag-anchor'} >
                <DragIndicator />
              </HoverTools>
              <HoverTools className={'product-action-buttons'} >
                <CopyIcon onClick={handleCopyGroup} />
                <DeleteIcon onClick={handleDeleteGroup} />
              </HoverTools>
              <RectangularSkeleton className={'product-thumbnail'} rounded animation={false} />
              <TextSkeleton className={'thumbnail'} rounded animation={false} width={220} />
              <TextSkeleton className={'thumbnail'} rounded animation={false} width={110} />
            </div>
          }
        </div>
      }
    </>
  );
}

function ProductView() {
  const {hideActionButtons, salesDocPdf, selection, setSelection} = usePageContext();
  const groupId = selection.groupId;
  const handleBack = useCallback((e) => {
    e.stopPropagation();
    setSelection({section: SalesDocSections.documentHeader});
  }, [setSelection]);

  return (
    <div className={'product-view'}>
      <Button prefix={BackIcon} text={'Back'} actionStandard className={'back-button'} onClick={handleBack} />
      <Row wrap pad className={'product-view-container'} ref={salesDocPdf}>
        <Column className={'presentation-image-column'}>
          <PresentationImageGallery groupId={groupId} />
        </Column>
        <Column className={'presentation-data-column'}>
          <ProductViewVariants groupId={groupId} />
          <ProductViewDecorations groupId={groupId} />
          <ProductViewAdditionalCharges groupId={groupId} />
          {!hideActionButtons && <ProductViewActionButtons groupId={groupId} />}
        </Column>
      </Row>
    </div>
  );
}

function PresentationImageGallery({groupId}) {
  const {salesDoc, isBuilder} = usePageContext();
  const variantsInGroup = salesDoc.getProductVariantsInGroup(groupId);
  const items = salesDoc.getVariantItems(variantsInGroup[0]?.variantId).sort((a, b) => (+a.quantity) - (+b.quantity));
  const firstItem = items[0];
  const images = firstItem?.images ?? [];

  return (
    <>
      {isBuilder &&
        <SalesDocSection section={SalesDocSections.productImage} groupId={groupId} variantId={firstItem?.variantId}>
          <ImageGallery images={images} thumbs pagination />
        </SalesDocSection>
      }
      {!isBuilder &&
        <ImageGallery images={images} zoom thumbs hoverZoom pagination />
      }
    </>
  );
}

function ProductViewVariants({groupId}) {
  const {salesDoc, isBuilder} = usePageContext();

  const variantsInGroup = salesDoc.getProductVariantsInGroup(groupId);

  const items = salesDoc.getVariantItems(variantsInGroup[0]?.variantId).sort((a, b) => (+a.quantity) - (+b.quantity));
  const firstItem = items[0];

  const colors = firstItem?.colors ?? [];
  const sizes = firstItem?.sizes ?? [];

  return (
    <>
      {isBuilder &&
        <SalesDocSection section={SalesDocSections.product} groupId={groupId} variantId={firstItem?.variantId} itemId={firstItem?.itemId}>
          <Row wrap gap className={'presentation-variant'}>
            {firstItem?.name && <HeadingText x32>{firstItem.name}</HeadingText>}
            {!firstItem?.name && <HeadingText x32>Product</HeadingText>}

            {firstItem?.description && <WysiwygText text={firstItem.description} />}
            {!firstItem?.description && <TextSkeleton animation={false} width={400} />}

            {firstItem &&
              <>
                {firstItem.showPricing &&
                  <>
                    <HeadingText x20>Pricing</HeadingText>
                    <Table className={'presentation-pricing-table'}>
                      <TableRow>
                        <TableCell>Qty</TableCell>
                        {items.map((variant) => (
                          <TableCell key={variant.itemId}>{variant.quantity}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>Rate/Unit</TableCell>
                        {items.map((variant) => (
                          <TableCell key={variant.itemId}>
                            <div className={'currency-prefix'}>{asCurrencyString(variant.unitPrice)}</div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </Table>
                  </>
                }
                {firstItem.showItem &&
                  <>
                    {(colors.length > 0 || sizes.length > 0) &&
                      <HeadingText x20>Product Options</HeadingText>
                    }
                    {colors.length > 0 &&
                      <Column>
                        <BodyText dark>Colors Available</BodyText>
                        <ChipList chips={colors} />
                      </Column>
                    }
                    {sizes.length > 0 &&
                      <div className={'presentation-row'}>
                        <BodyText dark>Sizes Available</BodyText>
                        <ChipList chips={sizes} sortFunction={sortSizes} />
                      </div>
                    }
                  </>
                }
              </>
            }
            {/* Skeleton product */}
            {!firstItem &&
              <>
                <HeadingText x20>Pricing</HeadingText>
                <Table className={'presentation-pricing-table'}>
                  <TableRow>
                    <TableCell>Qty</TableCell>
                    <TableCell><TextSkeleton animation={false} /></TableCell>
                    <TableCell><TextSkeleton animation={false} /></TableCell>
                    <TableCell><TextSkeleton animation={false} /></TableCell>
                    <TableCell><TextSkeleton animation={false} /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Rate/Unit</TableCell>
                    <TableCell><TextSkeleton animation={false} /></TableCell>
                    <TableCell><TextSkeleton animation={false} /></TableCell>
                    <TableCell><TextSkeleton animation={false} /></TableCell>
                    <TableCell><TextSkeleton animation={false} /></TableCell>
                  </TableRow>
                </Table>
              </>
            }
          </Row>
        </SalesDocSection>
      }
      {!isBuilder && firstItem &&
        <Row wrap gap className={'presentation-variant'}>
          <HeadingText x32>{firstItem.name}</HeadingText>
          <WysiwygText text={firstItem.description} />

          {firstItem.showPricing &&
            <>
              <HeadingText x20>Pricing</HeadingText>
              <Table className={'presentation-pricing-table'}>
                <TableRow>
                  <TableCell>Qty</TableCell>
                  {items.map((variant) => (
                    <TableCell key={variant.itemId}>{variant.quantity}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>Rate/Unit</TableCell>
                  {items.map((variant) => (
                    <TableCell key={variant.itemId}>
                      <div className={'currency-prefix'}>{asCurrencyString(variant.unitPrice)}</div>
                    </TableCell>
                  ))}
                </TableRow>
              </Table>
            </>
          }
          {firstItem.showItem &&
            <>
              {(colors.length > 0 || sizes.length > 0) &&
                <HeadingText x20>Product Options</HeadingText>
              }
              {colors.length > 0 &&
                <div className={'presentation-row'}>
                  <BodyText dark>Colors Available</BodyText>
                  <ChipList chips={colors} />
                </div>
              }
              {sizes.length > 0 &&
                <div className={'presentation-row'}>
                  <BodyText dark>Sizes Available</BodyText>
                  <ChipList chips={sizes} />
                </div>
              }
            </>
          }
        </Row>
      }
    </>
  );
}

function ProductViewDecorations({groupId}) {
  const {salesDoc, setSelection, isBuilder} = usePageContext();
  // Below we're filtering items that have showItem set to false, so they don't render in guest view
  const decorationItems = salesDoc.getItemsInGroup(groupId).filter((item) => (item.type === SalesDocItem.Type.DECORATION && (isBuilder || item.showItem)));
  const includedGroupedByVariantId = Object.groupBy(decorationItems.filter((item) => item.rollupSellPrice), ({variantId}) => variantId);
  const availableFlatRateGroupedByVariantId = Object.groupBy(decorationItems.filter((item) => !item.rollupSellPrice && item.priceMode === 'flatRate'), ({variantId}) => variantId);
  const availableQuantityBasedGroupedByVariantId = Object.groupBy(decorationItems.filter((item) => !item.rollupSellPrice && item.priceMode === 'quantityBased'), ({variantId}) => variantId);
  const showPricing = salesDoc.getFirstProductVariantItemInGroup(groupId)?.showPricing ?? true;

  const showThingsToolbox = useCallback((event) => {
    event.stopPropagation();
    setSelection({section: SalesDocSections.decoration, groupId: groupId});
  }, [setSelection, groupId]);

  return (
    <>
      {isBuilder &&
        <SalesDocSection section={SalesDocSections.decoration} groupId={groupId} hideEditIcon>
          {decorationItems.length > 0 &&
            <Row wrap gap className={'presentation-decorations hover-tools-container'}>
              <HeadingText x20>Decorations</HeadingText>
              {!isEmpty(includedGroupedByVariantId) &&
                <div className={'presentation-row'}>
                  <BodyText dark>Included Decorations</BodyText>
                  <AddOnsChipListSelectable groupedItems={includedGroupedByVariantId} section={SalesDocSections.decoration} showPricing={showPricing} />
                </div>
              }
              <div className={'presentation-row'}>
                {(!isEmpty(availableFlatRateGroupedByVariantId) || !isEmpty(availableQuantityBasedGroupedByVariantId)) &&
                  <BodyText dark>Decorations Available</BodyText>
                }
                {!isEmpty(availableFlatRateGroupedByVariantId) &&
                  <AddOnsChipListSelectable
                    groupedItems={availableFlatRateGroupedByVariantId}
                    section={SalesDocSections.decoration}
                    showPricing={showPricing}
                  />
                }
              </div>
              {!isEmpty(availableQuantityBasedGroupedByVariantId) &&
                <AddOnsChipListAndPricingTableSelectable
                  groupedItems={availableQuantityBasedGroupedByVariantId}
                  section={SalesDocSections.decoration}
                  showPricing={showPricing}
                />
              }
              <HoverTools className={'add-item-button'} >
                <Button
                  actionPrimary
                  prefix={AddIcon}
                  text='Add another decoration'
                  onClick={showThingsToolbox}
                />
              </HoverTools>
            </Row>
          }

          {/* Skeleton decoration */}
          {decorationItems.length === 0 &&
            <div className={'presentation-decorations skeleton'}>
              <HeadingText x20>Decorations
                <ToolTip right tip={'If no Decorations are added, this will not be shown to your customer.'}>
                  <VisibilityOffIcon />
                </ToolTip>
              </HeadingText>
              <TextSkeleton animation={false} width={300} />
              <TextSkeleton animation={false} width={200} />
            </div>
          }
        </SalesDocSection>
      }
      {!isBuilder && decorationItems.length > 0 &&
        <Row wrap gap className={'presentation-decorations'}>
          <HeadingText x20>Decorations</HeadingText>
          {!isEmpty(includedGroupedByVariantId) &&
            <div className={'presentation-row'}>
              <BodyText dark>Included Decorations</BodyText>
              <AddOnsChipList groupedItems={includedGroupedByVariantId} section={SalesDocSections.decoration} showPricing={showPricing} />
            </div>
          }
          <div className={'presentation-row'}>
            {(!isEmpty(availableFlatRateGroupedByVariantId) || !isEmpty(availableQuantityBasedGroupedByVariantId)) &&
              <BodyText dark>Decorations Available</BodyText>
            }
            {!isEmpty(availableFlatRateGroupedByVariantId) &&
              <AddOnsChipList groupedItems={availableFlatRateGroupedByVariantId} section={SalesDocSections.decoration} showPricing={showPricing} />
            }
          </div>
          {!isEmpty(availableQuantityBasedGroupedByVariantId) &&
            <AddOnsChipListAndPricingTable
              groupedItems={availableQuantityBasedGroupedByVariantId}
              section={SalesDocSections.decoration}
              showPricing={showPricing}
            />
          }
        </Row>
      }
    </>
  );

}

function ProductViewAdditionalCharges({groupId}) {
  const {salesDoc, setSelection, isBuilder} = usePageContext();
  // Below we're filtering items that have showItem set to false, so they don't render in guest view
  const additionalCosts = isBuilder
    ? salesDoc.getItemsInGroup(groupId).filter((item) => (item.type === SalesDocItem.Type.ADDITIONAL_COST))
    : salesDoc.getItemsInGroup(groupId).filter((item) => (item.type === SalesDocItem.Type.ADDITIONAL_COST) && (!isBuilder && item.showItem));
  const includedGroupedByVariantId = Object.groupBy(additionalCosts.filter((item) => item.rollupSellPrice), ({variantId}) => variantId);
  const availableFlatRateGroupedByVariantId = Object.groupBy(additionalCosts.filter((item) => !item.rollupSellPrice && item.priceMode === 'flatRate'), ({variantId}) => variantId);
  const availableQuantityBasedGroupedByVariantId = Object.groupBy(additionalCosts.filter((item) => !item.rollupSellPrice && item.priceMode === 'quantityBased'), ({variantId}) => variantId);
  const showPricing = salesDoc.getFirstProductVariantItemInGroup(groupId)?.showPricing ?? true;

  const showThingsToolbox = useCallback((event) => {
    event.stopPropagation();
    setSelection({section: SalesDocSections.additionalCost, groupId: groupId});
  }, [setSelection, groupId]);

  return (
    <>
      {isBuilder &&
        <SalesDocSection section={SalesDocSections.additionalCost} groupId={groupId} hideEditIcon>
          {additionalCosts.length > 0 &&
            <Row wrap gap className={'presentation-additional-charges hover-tools-container'}>
              <HeadingText x20>Additional Charges</HeadingText>
              {!isEmpty(includedGroupedByVariantId) &&
                <div className={'presentation-row'}>
                  <BodyText dark>Included Additional Charges</BodyText>
                  <AddOnsChipListSelectable
                    groupedItems={includedGroupedByVariantId}
                    section={SalesDocSections.additionalCost}
                    showPricing={showPricing}
                  />
                </div>
              }
              <div className={'presentation-row'}>
                {(!isEmpty(availableFlatRateGroupedByVariantId) || !isEmpty(availableQuantityBasedGroupedByVariantId)) &&
                  <BodyText dark>Other Charges</BodyText>
                }
                {!isEmpty(availableFlatRateGroupedByVariantId) &&
                  <AddOnsChipListSelectable
                    groupedItems={availableFlatRateGroupedByVariantId}
                    section={SalesDocSections.additionalCost}
                    showPricing={showPricing}
                  />
                }
              </div>
              {!isEmpty(availableQuantityBasedGroupedByVariantId) &&
                <AddOnsChipListAndPricingTableSelectable
                  groupedItems={availableQuantityBasedGroupedByVariantId}
                  section={SalesDocSections.additionalCost}
                  showPricing={showPricing}
                />
              }
              <HoverTools className={'add-item-button'} >
                <Button
                  actionPrimary
                  prefix={AddIcon}
                  text='Add additional charges'
                  onClick={showThingsToolbox}
                />
              </HoverTools>
            </Row>
          }

          {/* Skeleton additional changes */}
          {additionalCosts.length === 0 &&
            <div className={'presentation-additional-charges skeleton'}>
              <HeadingText x20>Additional Charges
                <ToolTip right tip={'If no Additional Charges are added, this will not be shown to your customer.'}>
                  <VisibilityOffIcon />
                </ToolTip>
              </HeadingText>
              <TextSkeleton animation={false} width={250} />
            </div>}
        </SalesDocSection>
      }
      {!isBuilder && additionalCosts.length > 0 &&
        <Row wrap gap className={'presentation-additional-charges'}>
          <HeadingText x20>Additional Charges</HeadingText>
          {!isEmpty(includedGroupedByVariantId) &&
            <div className={'presentation-row'}>
              <BodyText dark>Included Additional Charges</BodyText>
              <AddOnsChipList groupedItems={includedGroupedByVariantId} section={SalesDocSections.additionalCost} showPricing={showPricing} />
            </div>
          }
          <div className={'presentation-row'}>
            {(!isEmpty(availableFlatRateGroupedByVariantId) || !isEmpty(availableQuantityBasedGroupedByVariantId)) &&
              <BodyText dark>Other Charges</BodyText>
            }
            {!isEmpty(availableFlatRateGroupedByVariantId) &&
              <AddOnsChipList groupedItems={availableFlatRateGroupedByVariantId} section={SalesDocSections.additionalCost} showPricing={showPricing} />
            }
          </div>
          {!isEmpty(availableQuantityBasedGroupedByVariantId) &&
            <AddOnsChipListAndPricingTable
              groupedItems={availableQuantityBasedGroupedByVariantId}
              section={SalesDocSections.additionalCost}
              showPricing={showPricing}
            />
          }
        </Row>
      }
    </>
  );
}

function ProductViewActionButtons({groupId}) {
  const {
    salesDoc,
    isBuilder,
    hideActionButtons,
    handleDownloadPdf,
    pdfLoading,
    setShowComment,
    showComment,
    commentText,
    setCommentText,
    saveComment,
    commentSaving,
    selection,
    likeGroup
  } = usePageContext();
  const items = salesDoc.getItemsInGroup(groupId);
  const isLikedGroup = items[0].liked;

  const handleLikeGroup = useCallback(async (e) => {
    e.stopPropagation();
    if (isLikedGroup) {
      likeGroup({groupId: groupId, like: false});
    } else {
      likeGroup({groupId: groupId, like: true});
    }
  }, [groupId, likeGroup, isLikedGroup]);

  return (
    <>
      {isBuilder &&
        <SalesDocSection section={SalesDocSections.customerActionButtons} groupId={selection.groupId}>
          <Column className={'presentation-action-buttons'} gap>
            {salesDoc.template.cartButtonEnabled &&
              <ButtonTheme color={salesDoc.template.cartButtonColor}>
                <Button navMain text={salesDoc.template.cartButtonText} className={'add-to-cart-button'} />
              </ButtonTheme>
            }
            <Row gap>
              {salesDoc.template.commentButtonEnabled &&
                <ButtonTheme color={salesDoc.template.commentButtonColor}>
                  <Button navStandard text={salesDoc.template.commentButtonText} caps={false} />
                </ButtonTheme>
              }
              {salesDoc.template.pdfButtonEnabled &&
                <ButtonTheme color={salesDoc.template.pdfButtonColor}>
                  <Button navMinor prefix={PdfIcon} text={salesDoc.template.pdfButtonText} caps={false} />
                </ButtonTheme>
              }
            </Row>
          </Column>
        </SalesDocSection>
      }
      {!isBuilder && !hideActionButtons &&
        <Column className={'presentation-action-buttons'} gap>
          {salesDoc.template.cartButtonEnabled &&
            <ButtonTheme color={salesDoc.template.cartButtonColor}>
              <Button navMain text={salesDoc.template.cartButtonText} className={'add-to-cart-button'} />
            </ButtonTheme>
          }
          <Row gap>
            <FeatureFlag allow={featureFlags.likeProduct}>
              {salesDoc.template.likeButtonEnabled &&
                <ToolTip
                  top
                  tip={<span style={{whiteSpace: 'pre-line'}}>{isLikedGroup ? 'Remove Like' : 'We’ll be notified when \n you like products.'}</span>}
                >
                  <ButtonTheme color={salesDoc.template.likeButtonColor}>
                    <Button navStandard={!isLikedGroup} navPrimary={isLikedGroup} prefix={LikeIcon} onClick={handleLikeGroup} />
                  </ButtonTheme>
                </ToolTip>
              }
            </FeatureFlag>
            {salesDoc.template.commentButtonEnabled &&
              <ButtonTheme color={salesDoc.template.commentButtonColor}>
                <Button navStandard text={salesDoc.template.commentButtonText} caps={false} onClick={() => setShowComment(true)} />
              </ButtonTheme>
            }
            {salesDoc.template.pdfButtonEnabled &&
              <ButtonTheme color={salesDoc.template.pdfButtonColor}>
                <Button navMinor prefix={PdfIcon} text={salesDoc.template.pdfButtonText} noWrap loading={pdfLoading} caps={false} onClick={handleDownloadPdf} />
              </ButtonTheme>
            }
          </Row>
          {showComment &&
            <>
              <Row>
                <TextInput placeholder={'Leave a comment'} rows={5} value={commentText} multiline onChange={(e) => setCommentText(valueFromEvent(e))} />
              </Row>
              <Row gap>
                <Button navMain text={'Save'} onClick={() => saveComment({groupId})} loading={commentSaving} />
                <Button navMinor text={'Cancel'} onClick={() => setShowComment(false)} />
              </Row>
            </>
          }
        </Column>
      }
    </>
  );
}

const AddOnsChipListSelectable = ({groupedItems, section, showPricing}) => {
  const {salesDoc, selection, setSelection, currencySymbol} = usePageContext();

  const copyVariant = useCallback((e, variantId) => {
    e.stopPropagation();
    salesDoc.copyVariants(variantId);
  }, [salesDoc]);
  const deleteVariant = useCallback((e, variantId) => {
    e.stopPropagation();
    salesDoc.deleteVariants(variantId);
  }, [salesDoc]);

  const selectItem = useCallback((e, item) => {
    e.stopPropagation();
    setSelection({
      section: section,
      groupId: item.groupId,
      itemId: item.itemId,
      variantId: item.variantId
    });
  }, [section, setSelection]);

  return (
    <Row wrap className={'chip-container'}>
      {Object.entries(groupedItems).map(([key, itemGroup]) => {
        const firstItem = itemGroup[0];
        const selected = selection
          && (selection.section === section)
          && selection.groupId === firstItem.groupId
          && (selection.variantId === firstItem.variantId);
        const flatRatePrice = firstItem.rollupSellPrice || !showPricing ? '' : ` (+${currencySymbol}${asCurrencyRateString(firstItem.unitPrice)})`;
        const tip = `${firstItem.position ? `position: ${firstItem.position}\n` : ''}${firstItem.color ? `color: ${firstItem.color}\n` : ''}${firstItem.size ? `size: ${firstItem.size}\n` : ''}`;

        return (
          <Chip
            key={key}
            onClick={(e) => selectItem(e, firstItem)}
            className={['selectable-chip', selected && 'selected', (!firstItem.showItem && firstItem.rollupSellPrice) && 'item-hidden-from-customer']}
          >
            <div className={'visibility-off-icon'}>
              <ToolTip tip={<span style={{whiteSpace: 'pre-line'}}>{'This item will not be \n shown to your customer.'}</span>} right disabled={firstItem.showItem}>
                <VisibilityOffIcon fontSize='small' />
              </ToolTip>
            </div>
            <HoverTools className={'selection-action-buttons'} >
              {!firstItem.isDecorationSetupCost() &&
                <CopyIcon onClick={(e) => copyVariant(e, firstItem.variantId)} />
              }
              <DeleteIcon onClick={(e) => deleteVariant(e, firstItem.variantId)} />
            </HoverTools>
            {`${stripHTML(firstItem.getSubstitutedDescription())}${flatRatePrice}`}
            {section === SalesDocSections.decoration && tip.length > 0 &&
              <ToolTip top tip={<div style={{whiteSpace: 'pre-line'}}>{tip}</div>}>
                <InfoIcon fontSize='small' />
              </ToolTip>
            }
          </Chip>
        );
      })
      }
    </Row>
  );
};

const AddOnsChipList = ({groupedItems, section, showPricing}) => {
  const {company} = usePageContext();
  const currencySymbol = company.currencySymbol || '$';
  return (
    <Row wrap className={'chip-container'}>
      {Object.entries(groupedItems).map(([key, itemGroup]) => {
        const firstItem = itemGroup[0];
        const flatRatePrice = firstItem.rollupSellPrice || !showPricing ? '' : ` (+${currencySymbol}${asCurrencyRateString(firstItem.unitPrice)})`;
        const tip = `${firstItem.position ? `position: ${firstItem.position}\n` : ''}${firstItem.color ? `color: ${firstItem.color}\n` : ''}${firstItem.size ? `size: ${firstItem.size}\n` : ''}`;
        return (
          <Chip key={key} text={`${stripHTML(firstItem.getSubstitutedDescription())}${flatRatePrice}`}>
            {section === SalesDocSections.decoration && tip.length > 0 &&
              <ToolTip top tip={<div style={{whiteSpace: 'pre-line'}}>{tip}</div>}>
                <InfoIcon fontSize='small' />
              </ToolTip>
            }
          </Chip>
        );
      })}
    </Row>
  );
};

const AddOnsChipListAndPricingTableSelectable = ({groupedItems, section, showPricing}) => {
  const {salesDoc} = usePageContext();

  const copyVariant = useCallback((e, variantId) => {
    e.stopPropagation();
    salesDoc.copyVariants(variantId);
  }, [salesDoc]);

  const deleteVariant = useCallback((e, variantId) => {
    e.stopPropagation();
    salesDoc.deleteVariants(variantId);
  }, [salesDoc]);

  return (
    <>
      {Object.entries(groupedItems).map(([key, items]) => {
        const tip = `${items[0].position ? `position: ${items[0].position}\n` : ''}${items[0].color ? `color: ${items[0].color}\n` : ''}${items[0].size ? `size: ${items[0].size}\n` : ''}`;
        const firstItem = items[0];
        return (
          <SalesDocSection
            key={key}
            section={section}
            groupId={firstItem.groupId}
            variantId={firstItem.variantId}
            itemId={firstItem.itemId}
            className={['chip-pricing-table-section', showPricing && 'pricing-table-border']}
          >
            <HoverTools className={'section-selected-show selection-action-buttons'} >
              {!firstItem.isDecorationSetupCost() &&
                <CopyIcon onClick={(e) => copyVariant(e, firstItem.variantId)} />
              }
              <DeleteIcon onClick={(e) => deleteVariant(e, firstItem.variantId)} />
            </HoverTools>

            <Chip text={stripHTML(firstItem.getSubstitutedDescription())} className={'selectable-chip'}>
              {section === SalesDocSections.decoration && tip.length > 0 &&
                <ToolTip top tip={<div style={{whiteSpace: 'pre-line'}}>{tip}</div>}>
                  <InfoIcon fontSize='small' />
                </ToolTip>
              }
            </Chip>

            {showPricing &&
              <Table className={'presentation-pricing-table'}>
                <TableRow>
                  <TableCell>Qty</TableCell>
                  {items.map((item) => (<TableCell key={item.itemId}>{item.quantity}</TableCell>))}
                </TableRow>
                <TableRow>
                  <TableCell>Rate/Unit</TableCell>
                  {items.map((item) => (
                    <TableCell key={item.itemId}>
                      <div className={'currency-prefix'}>{asCurrencyString(item.unitPrice)}</div>
                    </TableCell>))}
                </TableRow>
              </Table>
            }
          </SalesDocSection>
        );
      })}
    </>
  );
};

function AddOnsChipListAndPricingTable({groupedItems, section, showPricing}) {
  return (
    <>
      {Object.entries(groupedItems).map(([key, items]) => {
        const tip = `${items[0].position ? `position: ${items[0].position}\n` : ''}${items[0].color ? `color: ${items[0].color}\n` : ''}${items[0].size ? `size: ${items[0].size}\n` : ''}`;
        return (
          <div key={key}>
            <Chip text={stripHTML(items[0].getSubstitutedDescription())}>
              {section === SalesDocSections.decoration && tip.length > 0 &&
                <ToolTip top tip={<div style={{whiteSpace: 'pre-line'}}>{`${items[0].position ? `position: ${items[0].position}\n` : ''}${items[0].color ? `color: ${items[0].color}\n` : ''}${items[0].size ? `size: ${items[0].size}\n` : ''}`}</div>}>
                  <InfoIcon fontSize='small' />
                </ToolTip>
              }
            </Chip>
            {showPricing &&
              <Table className={'presentation-pricing-table'}>
                <TableRow>
                  <TableCell>Qty</TableCell>
                  {items.map((item) => (<TableCell key={item.itemId}>{item.quantity}</TableCell>))}
                </TableRow>
                <TableRow>
                  <TableCell>Rate/Unit</TableCell>
                  {items.map((item) => (
                    <TableCell key={item.itemId}>
                      <div className={'currency-prefix'}>{asCurrencyString(item.unitPrice)}</div>
                    </TableCell>))}
                </TableRow>
              </Table>
            }
          </div>
        );
      })}
    </>
  );
}
