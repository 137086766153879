// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {makeStyles, MenuItem, Select} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {change as _change, Field, formValueSelector} from 'redux-form';
import {DecorationService} from '../../../servicesOld/DecorationService';
import DecorationAutocomplete from '../../decorations/DecorationAutocomplete';
import {stripHTML} from '../../../utils/stripHtml';

const useStyles = makeStyles((theme) => ({
    textField: {
        ['& fieldset']: {
            borderRadius: '0 4px 4px 0',
            borderLeftColor: theme.colors.white,
        },
    },
    selectField: {
        ['& fieldset']: {
            borderRadius: '0 4px 4px 0',
            borderLeftColor: theme.colors.white,
        },
    },
}));

export const DecorationField = (props) => {
    // Note: quote has buyPrice; Job has none; PO has rate
    const {field, meta, rateColumn = 'buyPrice', product} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const selector = formValueSelector(meta.form);

    const quantitySelector = (state) => selector(state, 'quantity');
    const decorationSelector = (state) => selector(state, `${field}.decoration`);
    const descriptionSelector = (state) => selector(state, `${field}.description`);

    const quantity = useSelector(quantitySelector);
    const decoration = useSelector(decorationSelector);
    const description = useSelector(descriptionSelector);

    const change = (f, value) => dispatch(_change(meta.form, f, value));
    const linkedToProduct = product && product.decorations && product.decorations.find((field) => decoration && decoration._id === field._id);

    const [inputType, setInputType] = useState(null);

    const setDecoration = (value) => {
        const decoService = new DecorationService(value);

        if (!value || !value._id) {
            clearValues();
            return;
        }

        change(`${field}.decoration`, value);
        change(`${field}.decorationId`, value._id);
        change(`${field}.description`, value.name);
        change(`${field}.name`, value.name);
        change(`${field}.setupPrice`, value.setupPrice);
        change(`${field}.${rateColumn}`, decoService.getPrice(quantity));
        change(`${field}.vendor`, value.vendor);
    };

    const clearValues = () => {
        change(`${field}.decoration`, null);
        change(`${field}.decorationId`, null);
        change(`${field}.description`, '');
        change(`${field}.name`, '');
        change(`${field}.setupPrice`, null);
        change(`${field}.${rateColumn}`, null);
        change(`${field}.additionalCost`, null);
        change(`${field}.markup`, null);
        change(`${field}.vendor`, null);
    };

    useEffect(() => {
        setInputType((prevVal) => {
            const newVal = decoration && decoration._id
                ? linkedToProduct
                    ? 'product'
                    : 'hoops'
                : description
                    ? 'adhoc'
                    : 'hoops';
            return (newVal);
        }
        );
    }, [decoration, linkedToProduct, description]);

    const handleInputTypeChange = (e) => {
        clearValues();
        setInputType(e.target.value);
    };

    const handleAdhocText = (e) => {
        change(`${field}.description`, e.target.value);
    };

    const HoopsInput = () => {
        const classes = useStyles();
        const textFieldProps = {
            margin: 'dense',
            variant: 'outlined',
            placeholder: 'Search Decorations',
            className: classes.selectField,
            style: {margin: '8px 0 4px 0'},
            inputProps: {className: classes.inputClass,}
        };
        return (
            <Field
                fullWidth
                name={`${field}.decoration`}
                component={DecorationAutocomplete}
                productId={product && inputType === 'product' ? product._id : null}
                hideButton={inputType === 'product'}
                onSelect={setDecoration}
                input={{
                    onChange: (v) => {
                        clearValues();
                        setDecoration(v);
                    },
                    value: decoration?.name ?? '',
                }}
                textFieldProps={textFieldProps}
            />
        );
    };

    const AdhocInput = () => (
            <TextField
                fullWidth
                variant='outlined'
                margin='dense'
                placeholder='Decoration Name'
                defaultValue={stripHTML(description)}
                onBlur={handleAdhocText}
                className={classes.selectField}
                inputProps={{className: classes.inputClass}}
            />
        );

    const handleDecorationField = (inputType) => {
        switch (inputType) {
            case 'hoops':
                return <HoopsInput {...props} />;
            case 'product':
                return <HoopsInput {...props} linkedToProduct={true} />;
            case 'adhoc':
                return <AdhocInput {...props} />;
            default:
                return <HoopsInput {...props} />;
        }
    };

    return (
        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' xs={12}>
            <Grid item xs={4}>
                <Select
                    value={inputType}
                    onChange={handleInputTypeChange}
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    style={{borderRadius: '4px 0 0 4px', borderRight: 'none', margin: '8px 0 4px 0'}}
                >
                    <MenuItem value='hoops'>Hoops</MenuItem>
                    {product && product.decorations.length > 0 ? <MenuItem value='product'>Linked to Product</MenuItem> : null}
                    <MenuItem value='adhoc'>Adhoc</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={8}>
                {inputType && handleDecorationField(inputType)}
            </Grid>
        </Grid>
    );
};

