import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {formatAddressOneLine} from '../utils';

export function useCompany(companyTradingEntityId, companyObject) {
  const company = useSelector((state) => state.companyReducer.company);

  return useMemo(() => {
    const companyTradingEntities = company.companyTradingEntities ?? [];
    const entity = {...(companyTradingEntities.find(({_id}) => _id === companyTradingEntityId) ?? companyTradingEntities[0] ?? {})};
    return {
      company: {
        ...companyObject,
        ...company,
        ...entity,
        _id: company._id,
        companyTradingEntities,
        address: entity.addresses?.length > 0 ? formatAddressOneLine(entity.addresses[0]) : '',
        currencySymbol: company.currencySymbol ?? companyObject?.currencySymbol ?? '$',
      }
    };
  }, [company, companyObject, companyTradingEntityId]);
}
