import {Card, Fade, Typography, withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import {connect} from 'react-redux';
import {Link, Redirect, Route, Switch, withRouter} from 'react-router-dom';
import hoops_salesDocs_image from '../../../assets/images/hoops-salesDocs-has-arrived.png';
import the_printed_shirt_co_logo from '../../../assets/images/the_printed_shirt_co.png';
import hoops_proofs from '../../../assets/images/proofs_reg_image.png';
import StarIcon from '@mui/icons-material/Star';

import {SubscriptionTypes} from '../../../componentsHoops';
import {
  FORGOT_PASSWORD_ROUTE,
  INVITED_USER_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  REGISTER_PROOFS_ROUTE,
  REGISTER_XERO_ROUTE, REGISTER_FULLYPROMOTED_ROUTE
} from '../../../containers/routes/PreAuthRoutes';
import {Register} from '../../../pages/RegisterPage';
import Logo from '../../Logo';
import AcceptInvite from '../AcceptInvite';
import ForgotPassword from '../ForgotPassword';
import XeroRegister from '../xero/RegisterXero';
import Login from './Login';
import {BodyText, HeadingText} from '../../../componentsLib/Text';
import {Column, Row} from '../../../componentsLib/Layout';

const styles = (theme) => ({
  registrationPage: {backgroundColor: theme.colors.white,},
  formContainer: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    height: '100vh',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: theme.spacing(3),
    }
  },

  formSection: {
    maxWidth: 540,
    minHeight: 750,
    [theme.breakpoints.down('sm')]: {maxWidth: 800,}
  },

  brandingContainer: {
    height: '100vh',
    color: theme.colors.white,
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    justifyContent: 'center',
  },

  deviceLogo: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: 50,
    }
  },

  deviceLine: {
    background: theme.palette.primary.main,
    height: 8,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
  },

  deviceWarning: {
    display: 'none',
    '& .MuiSvgIcon-root': {
      color: theme.colors.orange,
      display: 'block',
      margin: '4px auto'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      textAlign: 'center',
      background: `${theme.colors.orange}10`,
      border: `1px solid ${theme.colors.orange}`,
    },
  },

  tagLine: {color: theme.colors.white},

  shapeWhite: {
    width: 58,
    height: 6,
    borderRadius: 15,
    backgroundColor: theme.colors.white,
    marginTop: 8
  },
  shapeGrey: {
    width: 58,
    height: 6,
    borderRadius: 15,
    backgroundColor: theme.colors.grey.light,
    marginTop: 8
  },
  leftColumn: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    minHeight: 900,
    [theme.breakpoints.down('sm')]: {display: 'none',}
  },
  rightColumn: {
    height: '100vh',
    minHeight: 850
  },

  marketingBanner: {
    '& .text-heading, .text-body': {
      color: theme.colors.white,
      padding: theme.spacing(2)
    },
    '& img': {
      height: '50vh',
      minHeight: 450,
      maxWidth: 1000,
      maxHeight: 674
    },
  },
  marketingTestimonial: {
    paddingTop: theme.spacing(2),
    '& svg': {color: theme.colors.yellow,},
    '& .text-heading, .text-body': {padding: 0},
    '& img': {
      width: 'auto',
      height: 'auto',
      minHeight: 'unset',
      marginTop: theme.spacing(1)
    },
    '& .text-body': {fontStyle: 'italic'},
  },
  fullyPromotedProcess: {
    maxWidth: '50%',
    '& ol': {paddingInlineStart: '24px',},
    '& li': {
      fontSize: '1.25em',
      '& p': {fontSize: 'inherit',},
    },
    '& p': {
      marginBottom: '16px',
      fontSize: '1.25em',
    }
  },
});

const StandardBanner = ({classes}) => {
  const WHITE = '#FFF';
  return (
    <Fade
      in={true}
      timeout={{enter: 800,}}>
      <div>
        <Grid item xs={12}>
          <Link to={'/'}>
            <Logo
              fill={[
                WHITE,
                WHITE,
                WHITE
              ]}
            />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>We believe business can be easy</Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.shapeWhite} />
        </Grid>
      </div>
    </Fade>
  );
};

const MarketingBanner = ({classes}) => (
  <Grid item xs={12}>
    <Column alignCenter className={classes.marketingBanner}>
      <HeadingText x48 dark center>Modern Sales & Order<br /> Management Software</HeadingText>
      <HeadingText x24 center>All the tools you need to track, manage, organize & grow every aspect of your business.</HeadingText>
      <img src={hoops_salesDocs_image} alt='Start your free trial now' />
      <Column alignCenter className={classes.marketingTestimonial}>
        <Row justifyCenter>
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </Row>
        <HeadingText x24 center>“Hoops has changed our lives!”</HeadingText>
        <BodyText x16 center light>The Printed Shirt Co.</BodyText>
        <img src={the_printed_shirt_co_logo} alt='Start your free trial now' />
      </Column>
    </Column>
  </Grid>
);

const ProofsBanner = () => (
  <>
    <Grid item xs={12}>
      <Typography variant='h3' align='center'>Start Your 3-Day Free Trial</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='h5' align='center' gutterBottom>Try Every Feature. No Software to Install. <br />No Credit Card Required. </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='body1' align='center' gutterBottom>No more endless threads of emails. No more mistakes.<br />Your customers will love getting proofs from you.</Typography>
    </Grid>
    <Grid item xs={12}>
      <img src={hoops_proofs} width='80%' style={{display: 'block', margin: 'auto', paddingTop: 24, maxWidth: 600}} alt='Start your free trial now' />
    </Grid>
  </>
);

const FullyPromotedBanner = ({classes}) => (
  <>
    <Grid item xs={12}>
      <Typography variant='h3' align='center'>Sign up for Hoops x Fully Promoted</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='h5' align='center' gutterBottom>No Software to Install. Easy and Fast Registration.</Typography>
    </Grid>
    <Grid item xs={12} style={{maxWidth: '60%'}}>
      <Typography variant='body1' align='center' gutterBottom>
        The future of the Fully Promoted Business Management System is here. No more mistakes. Integration with accounting software & CRM. Simple and Fun Workflow Management.
      </Typography>
    </Grid>
    <Grid className={classes.fullyPromotedProcess} item xs={12}>
      <Typography variant='h6' gutterBottom>
        Here’s the Process:
      </Typography>
      <ol>
        <li>
          <Typography variant='body1' gutterBottom>
            <b>Sign up now</b> - your account has been carefully set up to closely match the Fully Promoted workflow
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom>
            <b>Please go to Settings &gt; Billing and add a credit card to your Hoops account</b>
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom>
            You will be billed for the subscription at $195 per month which includes 3 users. For each additional user from 4-6 users, the cost is $39 per
            month, for user 7 onwards, the cost is $35 per month.
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom>
            You will be billed $1,500 for the 3-month, deluxe Fully Promoted onboarding package
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom>
            We will be in touch to confirm which onboarding cohort you will be a part of
          </Typography>
        </li>
      </ol>
      <Typography variant='body1' gutterBottom>
        We’re looking forward to working with you
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        All prices are in AUD and include GST
      </Typography>
    </Grid>
  </>
);

class PublicWrapper extends React.Component {
  render() {
    const {classes} = this.props;

    return (
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        className={classes.registrationPage}
      >
        <Grid item md={8} className={classes.leftColumn}>

          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            className={classes.brandingContainer}
          >
            <Grid item>
              {/* centering the content */}
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
              >
                <Switch>
                  <Route path={LOGIN_ROUTE}>
                    <StandardBanner {...this.props} />
                  </Route>
                  <Route exact path={REGISTER_ROUTE}>
                    <MarketingBanner {...this.props} />
                  </Route>
                  <Route path={REGISTER_XERO_ROUTE}>
                    <MarketingBanner {...this.props} />
                  </Route>
                  <Route path={INVITED_USER_ROUTE}>
                    <StandardBanner {...this.props} />
                  </Route>
                  <Route path={REGISTER_PROOFS_ROUTE}>
                    <ProofsBanner {...this.props} />
                  </Route>
                  <Route path={REGISTER_FULLYPROMOTED_ROUTE}>
                    <FullyPromotedBanner {...this.props} />
                  </Route>
                  <Route path={FORGOT_PASSWORD_ROUTE}>
                    <StandardBanner {...this.props} />
                  </Route>
                </Switch>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} sm={12} className={classes.rightColumn}>

          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            className={classes.formContainer}
          >
            <Grid item>
              {/* centering the content */}

              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                className={classes.formSection}
              >

                <Switch>
                  <Route path={LOGIN_ROUTE}><Login /></Route>
                  <Route exact path={REGISTER_ROUTE}><Register subscriptionType={SubscriptionTypes.full} /></Route>
                  <Route path={REGISTER_XERO_ROUTE}><XeroRegister /></Route>
                  <Route path={INVITED_USER_ROUTE}><AcceptInvite /></Route>
                  <Route path={REGISTER_PROOFS_ROUTE}><Register subscriptionType={SubscriptionTypes.proofs} /></Route>
                  <Route path={REGISTER_FULLYPROMOTED_ROUTE}><Register subscriptionType={SubscriptionTypes.fullyPromoted} /></Route>
                  <Route path={FORGOT_PASSWORD_ROUTE}><ForgotPassword /></Route>
                  <Redirect to={LOGIN_ROUTE} />
                </Switch>
              </Grid>
            </Grid>

            <Card variant='outlined' className={classes.deviceWarning}>
              <WarningIcon fontSize='large' />
              <Typography variant='caption'>It looks like you’re on a small device. Hoops is not optimised for mobile devices. For the best experience, we recommend that you use a larger screen. </Typography>
            </Card>

          </Grid>

        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {auth: state.authReducer};
}

export default withRouter(connect(mapStateToProps)(
  withStyles(styles, {withTheme: true})(PublicWrapper)
));
