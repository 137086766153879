import {TableRow} from '@material-ui/core';
import {get} from 'lodash';
import React from 'react';
import {HoopsTableCell, HoopsTableCellCheckbox} from '../HoopsTable';

export const JobItemTableVariantAdditionalItemRow = ({
  additionalItem,
  checked = false,
  disabled = false,
  mode = 'checkbox',
  onChange = () => null
}) => {

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <TableRow>
      <HoopsTableCellCheckbox onChange={handleChange} mode={mode} checked={checked} disabled={disabled} />
      <HoopsTableCell colSpan={2}>
        <div dangerouslySetInnerHTML={{__html: additionalItem.description ?? additionalItem.name}} />
      </HoopsTableCell>
      <HoopsTableCell colSpan={5}>
        {get(additionalItem, 'vendor.name', '-')}
      </HoopsTableCell>
    </TableRow>
  );
};

export default JobItemTableVariantAdditionalItemRow;
