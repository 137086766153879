import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {AutoCompleteContainer, AutoCompleteList, AutoCompleteListItem, AutoCompletePopover, AutoCompleteTextInput} from '../Popovers';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.text-autocomplete-popover', () => ({
  '&.autocomplete-popover .scroll': {
    maxHeight: '200px',
    minHeight: 0,
    minWidth: 0,
  }
}));

export function TextAutoComplete({className, label, options = [], onChange, value = '', allowAnyValue, showAllOnExactMatch}) {
  const [search, setSearch] = useState('');

  const handleSearchChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const handleChange = useCallback((newValue) => {
    setSearch(newValue);
    onChange?.(newValue);
  }, [onChange]);

  const handleClear = useCallback(() => {
    setSearch('');
    onChange?.('');
  }, [onChange]);

  const mappedOptions = useMemo(() => options.map((option) => ({lower: option.toLowerCase(), option})), [options]);
  const [filteredOptions, highlightedValue] = useMemo(() => {
    const lowerCaseValue = search?.toLowerCase();
    let matches = mappedOptions.filter(({lower}) => lowerCaseValue && lower.includes(lowerCaseValue));
    let bestMatch = matches[0]?.option;
    const exactMatch = mappedOptions.findIndex(({lower}) => lower === lowerCaseValue);
    if (matches.length === 0) {
      // If there are no matches, show them all, so we can change easily
      matches = mappedOptions;
      bestMatch = null;
    } else {
      if (exactMatch >= 0 && showAllOnExactMatch) {
        // If there is an exact match, also show them all, so we can change easily
        matches = mappedOptions;
        bestMatch = matches[exactMatch].option;
      } else {
        // There are some matches, sort the matches that match at the start ahead of the rest
        const front = [], other = [];
        matches.forEach((match) => {
          if (match.lower.startsWith(lowerCaseValue)) {
            front.push(match);
          } else {
            other.push(match);
          }
        });
        matches = [...front, ...other];
      }
    }
    return [matches, bestMatch];
  }, [mappedOptions, search, showAllOnExactMatch]);

  const isAnyValue = !!value && !options.includes(value);

  return (
      <AutoCompleteContainer
          className={[className, 'text-autocomplete']}
          label={label}
          value={value}
          allowAnyValue={allowAnyValue}
          highlightedValue={highlightedValue}
          search={search}
          onChange={handleChange}
          onSearchChange={handleSearchChange}
      >
        <AutoCompleteTextInput value={value} clearable={isAnyValue} onClear={handleClear}/>
        <AutoCompletePopover className={'text-autocomplete-popover'}>
          <AutoCompleteList>
            {filteredOptions?.map((option) => (
                <AutoCompleteListItem key={option.option} value={option.option}>
                  {option.option}
                </AutoCompleteListItem>
            ))}
          </AutoCompleteList>
        </AutoCompletePopover>
      </AutoCompleteContainer>
  );
}

TextAutoComplete.propTypes = {
  className: HoopsPropTypes.className,
  label: HoopsPropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowAnyValue: HoopsPropTypes.bool,
  showAllOnExactMatch: HoopsPropTypes.bool,
};
