import {asNumber, listToMap} from '../utils';

export class Markups {
  markups;
  assignments;

  static MarkupTypes = {
    product: 'PRODUCT',
    decoration: 'DECORATION',
    additionalCost: 'ADDITIONAL_COST',
  }

  constructor(that) {
    if (that) {
      Object.assign(this, that);
    }
  }

  static fromApi(markups) {
    return new Markups({
      assignments: markups?.assignments ?? [],
      markups: listToMap(markups?.markups, '_id'),
    });
  }

  findMarkup({type, vendorId, brand, category, subCategory}) {
    let bestMatch;
    let bestCount = 0;
    let compare = {type, vendorId, brand, category, subCategory};
    for (const assignment of this.assignments) {
      let count = 0;
      for (const key of Object.keys(compare)) {
        if (assignment[key] != null) {
          if (compare[key] !== assignment[key]) {
            count = 0;
            break;
          }
          count += 1;
        }
      }
      if (count > bestCount) {
        bestCount = count;
        bestMatch = this.markups[assignment.markupId];
        if (count === 5) {
          // Perfect match
          break;
        }
      }
    }
    return bestMatch;
  }

  findMarkupValue({type, vendorId, brand, category, subCategory, quantity, price}) {
    const markup = this.findMarkup({type, vendorId, brand, category, subCategory});
    let value;
    let highestQuantity = 0;
    if (markup) {
      markup.markupRanges.forEach((r) => {
        if (price >= r.lowerPrice && price <= r.upperPrice && quantity >= r.quantity && r.quantity > highestQuantity) {
          highestQuantity = r.quantity;
          value = asNumber(r.percentage);
        }
      });
    }
    return value;
  }
}
