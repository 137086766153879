import {gql, useMutation} from '@apollo/client';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import Loading from '../../shared/Loading';
import PreviewQuoteContentComponent from './PreviewQuoteContentComponent';
import {SalesDoc} from '../../../pages/SalesDoc/Models/SalesDoc';

const GQL_MARK_AS_VIEWED = gql`
    mutation markAsViewed($quoteId: String!){
      markAsViewed(quoteId: $quoteId){
           _id
        }
    }
`;

function PreviewQuoteContent({renderSiblings, tenantView}) {
  const userDataSelector = (state) => state.authReducer;
  const quoteSelector = (state) => state.quoteReducer;
  const {quote} = useSelector(quoteSelector);
  const {userData} = useSelector(userDataSelector);
  const [markAsViewed] = useMutation(GQL_MARK_AS_VIEWED);
  const currentUserCompanyId = userData && userData.company && userData.companyId;
  const quoteCompanyId = quote && quote.company && quote.company._id;
  const quoteId = quote?._id;

  useEffect(() => {
    // Redirect all SalesDocs to SalesDoc Guest View Page
    if (quote?.documentType && (quote.documentType !== SalesDoc.Type.CLASSIC_QUOTE)) {
      window.location.assign(`/public/sales/${quote._id}/view`);
    }

    if (quoteId && quoteCompanyId && (quoteCompanyId !== currentUserCompanyId)
    ) {
      markAsViewed({variables: {quoteId: quoteId}}).catch(console.error);
    }
  }, [quote, quoteId, currentUserCompanyId, quoteCompanyId, markAsViewed]);

  return (
    <>
      {
        quote ?
          <PreviewQuoteContentComponent userData={userData} quote={quote} renderSiblings={renderSiblings && quote.isSibling} tenantView={tenantView} /> :
          <Loading text='Loading Quote...' />
      }
    </>
  );
}

export default PreviewQuoteContent;
