import {TableRow, Typography} from '@material-ui/core';
import {get} from 'lodash';
import React from 'react';
import {HoopsTableCell, HoopsTableCellCheckbox} from '../HoopsTable';

export const JobItemTableVariantDecorationRow = ({
    decoration,
    checked = false,
    disabled = false,
    mode = 'checkbox',
    onChange = () => null
}) => {
    const handleChange = (event) => {
        onChange(event.target.checked);
    }
    ;
    return (
        <TableRow>
            <HoopsTableCellCheckbox onChange={handleChange} mode={mode} checked={checked} disabled={disabled} />
            <HoopsTableCell colSpan={2}>
                <div dangerouslySetInnerHTML={{__html: decoration.description ?? decoration.name}} />
            </HoopsTableCell>
            <HoopsTableCell colSpan={1}>
                <Typography variant={'body2'}>{get(decoration, 'vendor.name', '-')}</Typography>
            </HoopsTableCell>
            <HoopsTableCell>{get(decoration, 'colour', '')}</HoopsTableCell>
            <HoopsTableCell>{get(decoration, 'size', '')}</HoopsTableCell>
            <HoopsTableCell>{get(decoration, 'position', '')}</HoopsTableCell>
            <HoopsTableCell>

            </HoopsTableCell>
        </TableRow>

    );
};

export default JobItemTableVariantDecorationRow;
