// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {TableRow} from '@material-ui/core';
import React from 'react';
import {ITEM_STATUSES} from '../../../constants/status';
import ChipSelect from '../../jobs/ChipSelect';
import {HoopsTableCell, HoopsTableCellCheckbox, useHoopsJobTableStyles} from '../HoopsTable';
import {stripHTML} from '../../../utils/stripHtml';

export const JobItemTableItemRow = ({
  jobItem = {},
  onChecked = () => null,
  checked = false,
  disabled = false,
  mode = 'checkbox'
}) => {
  const classes = useHoopsJobTableStyles();

  const handleOnChange = (event) => {
    const checked = event.target.checked;
    onChecked(checked);
  };

  return (
    <TableRow>
      <HoopsTableCellCheckbox onChange={handleOnChange} mode={mode} checked={checked} disabled={disabled} />
      <HoopsTableCell colSpan={4} className={classes.jobItemDescription}>
        {stripHTML(jobItem.description)}
      </HoopsTableCell>
      <HoopsTableCell />
      <HoopsTableCell>
        {jobItem.quantity}
      </HoopsTableCell>
      <HoopsTableCell>
        <ChipSelect
          activeOption={jobItem.status}
          disabled
          size='small'
          options={ITEM_STATUSES}
          fallbackOption={ITEM_STATUSES[0]}
        />
      </HoopsTableCell>
    </TableRow>

  );
};

export default JobItemTableItemRow;
