import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {DragIndicator} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {AutoCompleteContainer, AutoCompleteEndButtons, AutoCompleteList, AutoCompletePopover, AutoCompleteValueContainer} from '../Popovers';
import {HoopsPropTypes} from '../utils';
import {Button, StatusLabel, TextInput} from '../Basic';
import {colors, registerGlobalStyle} from '../../theme';
import {SelectItem} from './Select';
import {Row} from '../Layout';
import {DragDropContext, Draggable, Droppable} from '../DragDrop';
import {ColorPicker} from './ColorPicker';

registerGlobalStyle('.status-picker-popover', (theme) => ({
  width: 280,
  '.autocomplete-list': {'li': {marginTop: theme.spacing(-.5)},},
  '.status-label': {padding: theme.spacing(.75, 1.5),},
  '.status-edit-line': {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    columnGap: theme.spacing(1),
    '>*': {flex: 'none'},
    '.color-block': {
      width: theme.spacing(1.5),
      borderRadius: theme.shape.borderRadius,
      alignSelf: 'stretch',
      height: 'unset',
    },
    '.text-input': {
      flex: '1 1 auto',
      paddingBlock: theme.spacing(.75),
    },
    'button': {color: theme.colors.text.medium},
    '.drag-indicator': {color: theme.colors.text.medium},
  },
}));

export function StatusPicker({className, initialOpen, label, options: initialOptions, placement, value, onChange, onChangeOptions, onClose}) {
  const [editing, setEditing] = useState(false);
  const [changed, setChanged] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(initialOptions.map((option, index) => ({...option, id: `option${index}`})));
  }, [initialOptions]);

  const handleStartEditing = useCallback(() => {
    setEditing(true);
  }, []);

  const handleApply = useCallback(() => {
    if (onChangeOptions) {
      onChangeOptions(options.map(({statusLabel, color, originalLabel}) => (originalLabel ? {statusLabel, color, originalLabel} : {statusLabel, color})));
    }
    setEditing(false);
  }, [onChangeOptions, options]);

  const handleAddStatus = useCallback(() => {
    setOptions((prev) => [...prev, {statusLabel: 'NEW STATUS', color: colors.palette.blue, id: `option${prev.length}`}]);
    setChanged(true);
  }, []);

  const handleDragEnd = useCallback((result) => {
    if (result.destination) {
      setOptions((prev) => {
        const newOptions = [...prev];
        const [removed] = newOptions.splice(result.source.index, 1);
        newOptions.splice(result.destination.index, 0, removed);
        return newOptions;
      });
      setChanged(true);
    }
  }, []);

  const handleLabelChange = useCallback((index, newLabel) => {
    setOptions((prev) => {
      const newOptions = [...prev];
      newOptions[index] = {...newOptions[index], statusLabel: newLabel, originalLabel: newOptions[index].originalLabel ?? newOptions[index].statusLabel};
      return newOptions;
    });
    setChanged(true);
  }, []);

  const handleChangeColor = useCallback((index, newColor) => {
    setOptions((prev) => {
      const newOptions = [...prev];
      newOptions[index] = {...newOptions[index], color: newColor};
      return newOptions;
    });
    setChanged(true);
  }, []);

  const handleDelete = useCallback((index) => {
    setOptions((prev) => {
      const newOptions = [...prev];
      newOptions.splice(index, 1);
      return newOptions;
    });
    setChanged(true);
  }, []);

  const valueColor = options.find((option) => option.statusLabel === value)?.color;

  const isLastNew = changed && options.length > 0 && options[options.length - 1].statusLabel === 'NEW STATUS';

  return (
    <AutoCompleteContainer
      className={[className, 'status-picker']}
      label={label}
      value={value}
      onChange={onChange}
      onClose={onClose}
    >
      <AutoCompleteValueContainer className={classNames([className, 'input-outline select-value'])} initialOpen={initialOpen}>
        <StatusLabel statusLabel={value} color={valueColor} asButton/>
      </AutoCompleteValueContainer>
      <AutoCompletePopover className={'status-picker-popover'} placement={placement} noMinSize>
        {!editing &&
          <AutoCompleteList>
            {options.map((option) => (
              <SelectItem key={option.id} value={option.statusLabel}>
                <StatusLabel statusLabel={option.statusLabel} color={option.color}/>
              </SelectItem>
            ))}
          </AutoCompleteList>
        }
        {editing &&
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='droppable'>
              {options.map((option, index) => (
                <Draggable key={option.id} draggableId={option.id} index={index}>
                  <Row className={'status-edit-line'}>
                    <DragIndicator className={'drag-indicator'}/>
                    <ColorPicker className={'color-block'} color={option.color} onChange={(color) => handleChangeColor(index, color)}/>
                    <TextInput
                      autoFocus={index === options.length - 1 && isLastNew}
                      selectOnFocus={index === options.length - 1 && isLastNew}
                      value={option.statusLabel}
                      onChange={(e) => handleLabelChange(index, e.target.value)}
                    />
                    <Button prefix={DeleteIcon} onClick={() => handleDelete(index)}/>
                  </Row>
                </Draggable>
              ))}
            </Droppable>
          </DragDropContext>
        }
        {onChangeOptions &&
          <AutoCompleteEndButtons>
            {!editing &&
              <Button navMinor onClick={handleStartEditing}>Edit Statuses</Button>
            }
            {editing &&
              <>
                <Button navMinor onClick={handleAddStatus}>New Status</Button>
                <Button navMain disabled={!changed} onClick={handleApply}>Apply</Button>
              </>
            }
          </AutoCompleteEndButtons>
        }
      </AutoCompletePopover>
    </AutoCompleteContainer>
  );
}

StatusPicker.propTypes = {
  className: HoopsPropTypes.className,
  initialOpen: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  placement: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  onChangeOptions: HoopsPropTypes.func,
  onClose: HoopsPropTypes.func,
};
