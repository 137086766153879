import React, {createContext, useContext} from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {useCompany} from '../../hooks';

registerGlobalStyle('.hoops-page', () => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  minWidth: 600,
  overflow: 'hidden',
  '.page-scroll-container': {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'block',
    overflow: 'auto',
  },
}));

export const PageContext = createContext(null);
export const usePageContext = () => useContext(PageContext);

export function Page({className, context, title, children}) {
  const {company} = useCompany();
  const currencySymbol = context?.currencySymbol ?? company.currencySymbol;

  document.title = title ? `${title} | Hoops` : 'Hoops';

  document.documentElement.style.setProperty('--currencyContent', `"${currencySymbol}"`);
  document.documentElement.style.setProperty('--currencySymbol', `${currencySymbol}`);

  return (
    <div className={classNames([className, 'hoops-page'])}>
      <div className={'page-scroll-container'}>
        <PageContext.Provider value={context}>
          {children}
        </PageContext.Provider>
      </div>
    </div>
  );
}

Page.propTypes = {
  className: HoopsPropTypes.className,
  context: HoopsPropTypes.object,
  title: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
